import { createContext, useEffect, useReducer } from 'react';
import { getRegisteredCampaigns } from '../api/RegisteredCampaign';

const RegisteredCampaignsContext = createContext();

const ACTIONS = {
  GET_REGISTERED_CAMPAIGNS: 'GET',
};

const registeredCampaignReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.GET_REGISTERED_CAMPAIGNS:
      return { ...action.data };
    default:
      return state;
  }
};

const RegisteredCampaignsProvider = (props) => {
  const [registeredCampaigns, dispatch] = useReducer(
    registeredCampaignReducer,
    {}
  );

  useEffect(() => {
    updateRegisteredCampaigns();
  }, []);

  const updateRegisteredCampaigns = async () => {
    try {
      let data = await getRegisteredCampaigns();
      dispatch({
        type: ACTIONS.GET_REGISTERED_CAMPAIGNS,
        data: data.campaigns,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RegisteredCampaignsContext.Provider
      value={{ registeredCampaigns, dispatch, updateRegisteredCampaigns }}
    >
      {props.children}
    </RegisteredCampaignsContext.Provider>
  );
};

export { RegisteredCampaignsContext };

export default RegisteredCampaignsProvider;
