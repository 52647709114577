import AppApi from 'services/appApi';

/**
 * Get a csv file of all DNCs related to a campaign
 * @param {String} campaignId The campaign id
 * @returns {File} A csv file of DNCs associated with the campaign
 */
async function getCampaignDNC(campaignId) {
  try {
    let res = await AppApi.post('/downloadCampaignDNC', {
      projectId: campaignId,
    });

    if (res.status !== 200) {
      throw res;
    }
    return res.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}
/**
 * Get all the DNCs residing on the DNC service.
 * @returns {String} - CSV file containing all the DNCs
 */
async function getGlobalDncList() {
  try {
    let res = await AppApi.get('/dndService/getDnds');

    if (res.status !== 200) {
      throw res;
    }

    return res.data;
  } catch (error) {
    console.error(error);
    return '';
  }
}

/**
 * Inserts each row from dndFile into the DNC service
 * @param {File} dndFile - File containing a DNC phone number on each line
 * @returns {Boolean} - true on success
 */
async function addToGlobalDncList(dndFile) {
  try {
    let formData = new FormData();
    formData.append('dndService', dndFile);
    let res = await AppApi.post('/dndService/uploadDnds', formData);

    if (res.status !== 200) {
      throw res;
    }

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

/**
 * Remove DND phone numbers from numberFile
 * @param {File} numberFile - File to filter DNDs out of
 * @returns {String} File with DNDs removed
 */
async function filterDndsFromList(numberFile) {
  try {
    let formData = new FormData();
    formData.append('dndService', numberFile);
    let res = await AppApi.post('/dndService/filterPhoneNumbers', formData);

    if (res.status !== 200) {
      throw res;
    }

    if (!res.data) {
      // Return some blank data, so empty file
      // can be downloaded
      return ' ';
    }

    return res.data;
  } catch (error) {
    console.error(error);
    return '';
  }
}
export {
  getCampaignDNC,
  getGlobalDncList,
  addToGlobalDncList,
  filterDndsFromList,
};
