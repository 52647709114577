const { htmlIdGenerator } = require('@elastic/eui');

/**
 * Create a toast message with properties
 * @param {String} id ID of the toast
 * @param {String} title Title of toast
 * @param {String} text Body of toast
 * @param {String} iconType Eui icon type
 * @param {String} color Style of toast
 * @returns {Object} Toast object for EuiGlobalToastList
 */
function createToast(id, title, text, iconType, color) {
  return {
    id,
    title,
    text,
    iconType,
    color,
  };
}

/**
 * Create a warning toast message
 * @param {String} title Title of toast
 * @param {String|React.ReactElement} text Text/component to appear in body of toast
 * @returns {Object} Properties of a toast to create
 */
function createErrorToast(title, text) {
  return createToast(htmlIdGenerator()(), title, text, 'alert', 'danger');
}

/**
 * Create a success toast message
 * @param {String} title Title of toast
 * @param {String|React.ReactElement} text Text/component to appear in body of toast
 * @returns {Object} Properties of a toast to create
 */
function createSuccessToast(title, text) {
  return createToast(htmlIdGenerator()(), title, text, 'check', 'success');
}

export { createErrorToast, createSuccessToast };
