import { EuiSelect } from '@elastic/eui';
import React, { useState } from 'react';

export const SelectTelecomProvider = ({ onChange }) => {
  const [selectedProvider, setSelectedProvider] = useState(
    process.env.REACT_APP_DEFAULT_TELECOM_PROVIDER
  );
  const options = [
    { text: 'Bandwidth', value: 'bandwidth' },
    { text: 'Telnyx', value: 'telnyx' },
  ];

  return (
    <EuiSelect
      value={selectedProvider}
      options={options}
      onChange={(e) => {
        setSelectedProvider(e.target.value);
        onChange(e.target.value);
      }}
      disabled={true}
    />
  );
};

export default SelectTelecomProvider;
