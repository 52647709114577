import React from 'react';
import {
  EuiButtonEmpty,
  EuiDatePicker,
  EuiForm,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  htmlIdGenerator,
} from '@elastic/eui';
import { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import DownloadButton from 'components/widgets/DownloadButton';
/**
 * Modal for downloading a file on a specified date
 */
function DownloadFileModal({ title, onSubmit, closeModal, filename }) {
  const [selectedDate, setSelectedDate] = useState(
    moment().subtract(1, 'weeks')
  );
  const formId = htmlIdGenerator('download-file')();
  const MIN_DATE = moment().startOf('day').subtract(14, 'days');
  const MAX_DATE = moment().endOf('day');
  let isDateValid = MIN_DATE <= selectedDate && selectedDate <= MAX_DATE;

  return (
    <EuiModal onClose={closeModal}>
      <EuiModalHeader>{title}</EuiModalHeader>
      <EuiModalBody>
        <EuiForm component='form' id={formId}>
          <EuiDatePicker
            selected={selectedDate}
            onChange={(date) =>
              setSelectedDate(
                moment.max(moment.min(date.startOf('day'), MAX_DATE), MIN_DATE)
              )
            }
            isInvalid={!isDateValid}
            showTimeSelect={false}
            maxDate={MAX_DATE}
            minDate={MIN_DATE}
          />
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>
        <DownloadButton
          text='Download'
          filename={filename}
          request={onSubmit}
          requestParams={[new Date(selectedDate)]}
        />
      </EuiModalFooter>
    </EuiModal>
  );
}

DownloadFileModal.propTypes = {
  /**
   * Title of modal
   * @param {String} title - Title of modal
   */
  title: PropTypes.string.isRequired,

  /**
   * Async function called on submit
   * @param {Date} startDate - Starting date range
   * @param {Date} endDate - Ending date range
   */
  onSubmit: PropTypes.func.isRequired,

  /**
   * Function used to close the modal
   */
  closeModal: PropTypes.func.isRequired,
};

export default DownloadFileModal;
