/**
 * Validate the a substring appears in a string
 *
 * @param {String} root - String to search
 * @param {String} substring - String to find
 * @param {Boolean} [ignoreCase=false] - Ignore casing
 * @returns {Boolean} true if substring found in root
 */
export function findSubstring(root, substring, ignoreCase = false) {
  let flags = 'g';

  if (ignoreCase) {
    flags = 'i' + flags;
  }

  const regex = new RegExp(`\\b${substring}\\b`, flags);
  return root.search(regex) > -1;
}
