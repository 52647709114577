import 'icons'; // Import cached icons
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from 'pages/App/App';
import { EuiProvider } from '@elastic/eui';
import '@elastic/eui/dist/eui_theme_light.css';
import reportWebVitals from './reportWebVitals';
import roiTheme from './themes/research-opinions-insight.json';
import researchPollsTheme from './themes/research-polls.json';

/**
 * Get the applications EUI CSS styling
 *
 * @returns {Object} EUI Theme
 */
function getTheme() {
  switch (process.env.REACT_APP_PLATFORM_NAME) {
    case 'Research Opinion Insight':
      return roiTheme;
    case 'Research Polls':
      return researchPollsTheme;
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <EuiProvider colorMode={'light'} theme={getTheme()}>
      <App />
    </EuiProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
