import {
  EuiDatePicker,
  EuiDatePickerRange,
  EuiListGroup,
  EuiListGroupItem,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { downloadDupTimes } from 'api/DupTimes';
import DownloadButton from 'components/widgets/DownloadButton';
import moment from 'moment';
import 'react';
import { useState } from 'react';

/**
 * Component to download DUP Times
 *
 * @returns {React.ReactElement} DupTimes tool
 */
function DupTimes() {
  const [startDate, setStartDate] = useState(moment().subtract(1, 'months'));
  const [endDate, setEndDate] = useState(moment());

  return (
    <>
      <center>
        <EuiText>
          <h1>DUP Times</h1>
          <b>To download DUP times in a given range:</b>
          <EuiListGroup maxWidth={false}>
            <EuiListGroupItem label='1. Choose a start date.' />
            <EuiListGroupItem label='2. Choose an end date.' />
          </EuiListGroup>
        </EuiText>
        <EuiDatePickerRange
          startDateControl={
            <EuiDatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              startDate={startDate}
              endDate={endDate}
              isInvalid={startDate > endDate}
              showTimeSelect={false}
              maxDate={endDate}
              utcOffset={0}
            />
          }
          endDateControl={
            <EuiDatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              startDate={startDate}
              endDate={endDate}
              showTimeSelect={false}
              minDate={startDate}
              utcOffset={0}
            />
          }
        />
      </center>
      <EuiSpacer />
      <DownloadButton
        request={downloadDupTimes}
        requestParams={[startDate.toDate(), endDate.toDate()]}
        text='Download DUP times in range'
        filename={`duptimes (${startDate.format(
          'MMMMDD-YYYY'
        )} to ${endDate.format('MMMMDD-YYYY')}).csv`}
      />
      <EuiSpacer size='l' />
      <EuiText>
        <b>To download all DUP times</b>
      </EuiText>
      <EuiSpacer />
      <DownloadButton
        request={downloadDupTimes}
        text='Download all'
        filename={`duptimes (cumulative as of ${moment().format(
          'MMMMDD-YYYY'
        )}).csv`}
      />
    </>
  );
}

export default DupTimes;
