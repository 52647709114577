import AppApi from 'services/appApi';

/**
 * Query active campaigns from server.
 *
 * @returns {Array<Object>} A list of campaign objects
 */
async function getActiveCampaigns() {
  return AppApi.get('/getActiveCampaigns')
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }
      return res.data.activeProjects;
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
}

/**
 * Query completed campaigns from server.
 *
 * @returns {Array<Object>} A list of campaign objects
 */
async function getCompletedCampaigns() {
  return AppApi.get('/getCompletedCampaigns')
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }
      return res.data.completedProjects;
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
}

/**
 * Query all campaign ids from server for a given campaign type.
 *
 * @param {String} campaignType the campaign type
 * @returns {Array<String>} A list of campaign Ids in String type
 */
async function getAllCampaignIds(campaignType) {
  return AppApi.get(`/getAllCampaignIds/${campaignType}`)
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }
      return res.data.campaignIds;
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
}

/**
 * Query campaigns from server for a given page.
 *
 * @param {Array<String>} campaignIds a list of campaign id to retrieve
 * @returns {Array<Object>} A list of campaign objects
 */
async function getCampaignsForPage(campaignIds) {
  return AppApi.get('/getCampaignsForPage', {
    params: {
      campaignIds: JSON.stringify(campaignIds),
    },
  })
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }
      return res.data.campaigns;
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
}

/**
 * Query archived campaigns from server.
 *
 * @returns {Array<Object>} A list of campaign objects
 */
async function getArchivedCampaigns() {
  return AppApi.get('/getArchivedCampaigns')
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }
      return res.data.archivedProjects;
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
}

/**
 * Create a new campaign.
 *
 * @param {Object} campaignData An object of the form
 *    {
 *      name String,
 *      surveyLongUrl: String,
 *      from: String,
 *    }
 * @returns {Object} The created campaign object
 */
async function createCampaign(campaignData) {
  return AppApi.post('/createCampaign', campaignData)
    .then((res) => {
      if (res.status !== 201) {
        throw res;
      }
      return res.data.project;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
}

/**
 * Update an existing campaign
 *
 * @param {Object} campaignData An object of the form
 *    {
 *      projectId: String,
 *      updatedData: {
 *        name: String,
 *        surveyLongUrl: String,
 *        from: String,
 *      }
 *    }
 * @returns {Object} The edited campaign object
 */
async function editCampaign(campaignData) {
  return AppApi.post('/editCampaign', campaignData)
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }
      return res.data.project;
    })
    .catch((error) => {
      console.error(error);
      return {};
    });
}

/**
 * Delete an existing campaign
 *
 * @param {String} campignId Id of the campaign to delete
 * @returns {boolean} true on success
 */
async function deleteCampaign(campignId) {
  try {
    let result = await AppApi.delete('/deleteCampaign', {
      data: {
        projectId: campignId,
      },
    });

    if (result.status !== 200) {
      throw result;
    }

    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Get a campaign's data
 *
 * @param {String} campaignId Campaign ID
 * @returns {Object} Campaign data
 */
async function getCampaign(campaignId) {
  try {
    let result = await AppApi.get('/getCampaign', {
      params: {
        campaignId: campaignId,
      },
    });

    if (result.status !== 200) {
      throw result;
    }
    return result.data.campaign;
  } catch (error) {
    console.error(error);
    return {};
  }
}

/**
 * Mark a campaign as archived
 *
 * @param {String} projectId - Project ID
 * @returns {Boolean} success status
 */
async function archiveCampaign(projectId) {
  try {
    let result = await AppApi.post('/archiveCampaign', {
      projectId: projectId,
    });
    if (result.status !== 200) {
      throw result;
    }
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

/**
 * Set a campaign's active status
 *
 * @param {String} campaignId Campaign ID
 * @returns {Boolean} success status
 */
async function getCampaignStats(projectId) {
  try {
    let result = await AppApi.get('/getCampaignStats', {
      params: {
        projectId: projectId,
      },
    });
    return result.data.campaignStats;
  } catch (error) {
    return {};
  }
}

/**
 * Download all completes for a project as a CSV.
 *
 * @param {String} projectId - A project's projectId/campaignId
 * @returns {File|null} CSV data. null on failure
 */
async function downloadCompletesCsv(projectId) {
  try {
    let result = await AppApi.get('/downloadCompletesCsv', {
      params: { projectId },
    });
    return result.data;
  } catch (error) {
    console.error(error);
  }

  return null;
}

/**
 * Download all DNQs as a CSV
 *
 * @param {String} projectId - A project's projectId/campaignId
 * @returns {File} CSV data. null on failure
 */
async function downloadDnqsCsv(projectId) {
  try {
    let result = await AppApi.get('/downloadDnqsCsv', {
      params: { projectId },
    });
    return result.data;
  } catch (error) {
    console.error(error);
  }

  return null;
}

/**
 * Get campaign list and total count.
 *
 * @param {String} search - A string of the campaign name
 * @param {Number} pageIndex - A number of the page
 * @param {Number} pageSize - A number of the page size
 * @param {String} campaignType - A string of the campaign type
 * @returns {Object} - A list of campaign objects and total count
 */
async function getCampaigns(search, pageIndex, pageSize, campaignType) {
  try {
    const res = await AppApi.get('/getCampaigns', {
      params: {
        search: search,
        pageIndex: pageIndex,
        pageSize: pageSize,
        campaignType: campaignType,
      },
    });

    if (res.status !== 200) {
      throw res;
    }

    return res.data;
  } catch (error) {
    console.error(error);
    return {};
  }
}

export {
  getCampaign,
  getActiveCampaigns,
  getCompletedCampaigns,
  getAllCampaignIds,
  getCampaignsForPage,
  getArchivedCampaigns,
  createCampaign,
  editCampaign,
  deleteCampaign,
  archiveCampaign,
  getCampaignStats,
  downloadCompletesCsv,
  downloadDnqsCsv,
  getCampaigns,
};
