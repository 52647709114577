import { useState } from 'react';
import * as TokenService from 'services/tokenService';

/** Hook for token management */
function useTokens() {
  const [accessToken, setAccessToken] = useState(TokenService.getAccessToken());
  const [refreshToken, setRefreshToken] = useState(
    TokenService.getRefreshToken()
  );

  const manageTokens = (userTokens) => {
    // If there is no accessToken clear the accessToken in sessionStorage
    if (userTokens.tokens) {
      TokenService.setAccessToken(userTokens.tokens.access);
      TokenService.setRefreshToken(userTokens.tokens.refresh);
      setAccessToken(userTokens.tokens.access);
      setRefreshToken(userTokens.tokens.refresh);
    } else {
      TokenService.deleteRefreshToken();
      TokenService.deleteAccessToken();
      setAccessToken('');
      setRefreshToken('');
    }
  };

  return {
    setTokens: manageTokens,
    accessToken: accessToken,
    refreshToken: refreshToken,
  };
}

export default useTokens;
