import {
  EuiFormRow,
  EuiFieldText,
  EuiSpacer,
  EuiText,
  EuiListGroup,
  EuiListGroupItem,
  EuiButton,
} from '@elastic/eui';
import { useState } from 'react';
import { downloadContactHistory } from 'api/ContactHistoryApi';

/**
 * Get Contact History using the first name, last name, or phone number.
 */
function ContactHistory() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [buttonText, setButtonText] = useState('Email Contact History');
  const [buttonIcon, setButtonIcon] = useState('email');
  const [displayMessage, setDisplayMessage] = useState('');

  const isDownloadEnabled = firstName.trim() !== '' || lastName.trim() !== '';

  const getData = async () => {
    setIsLoading(true);
    setButtonIcon('clock');
    setButtonText('Processing request...');

    setDisplayMessage(
      'Your request has been received. An email will be sent in the next 5-10 minutes.'
    );

    setTimeout(() => {
      setButtonIcon('email');
      setButtonText('Email Contact History');
      setFirstName('');
      setLastName('');
      setPhoneNumber('');
      setIsLoading(false);
      setDisplayMessage('');
    }, 5000);

    await downloadContactHistory(firstName, lastName, phoneNumber);
  };

  return (
    <>
      <center>
        <EuiText>
          <h1>Contact History</h1>
          <b>To download contact history:</b>
          <EuiListGroup maxWidth={false}>
            <EuiListGroupItem label='1. Enter First Name or Last Name.' />
            <EuiListGroupItem label="2. Enter Phone Number (optional, but required to look up a person's conversation history)." />
          </EuiListGroup>
          {displayMessage && (
            <p style={{ color: 'green', marginTop: '10px' }}>
              {displayMessage}
            </p>
          )}
        </EuiText>
        <h1>Get Contact History</h1>

        <EuiFormRow label='First Name'>
          <EuiFieldText
            name='firstName'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder='Enter First Name'
          />
        </EuiFormRow>

        <EuiFormRow label='Last Name'>
          <EuiFieldText
            name='lastName'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder='Enter Last Name'
          />
        </EuiFormRow>

        <EuiFormRow label='Phone Number (Optional)'>
          <EuiFieldText
            name='phoneNumber'
            value={phoneNumber}
            onChange={(e) => {
              const input = e.target.value;
              if (/^\d*$/.test(input)) {
                setPhoneNumber(input);
              }
            }}
            placeholder='Enter Phone Number'
          />
        </EuiFormRow>

        <EuiSpacer />
        <EuiButton
          onClick={getData}
          isLoading={isLoading}
          isDisabled={!isDownloadEnabled}
          iconType={buttonIcon}
        >
          {buttonText}
        </EuiButton>
        <EuiSpacer size='l' />
        <EuiSpacer />
      </center>
    </>
  );
}

export default ContactHistory;
