import { useEffect } from 'react';
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
} from '@elastic/eui';
import Sidebar from 'components/Sidebar/Sidebar';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

/**
 * Template for a page with a sidebar
 */
function PageWithSidebar({
  bannerText,
  setBannerText,
  pageBody,
  sidebarButtons = [],
  homeButton = true,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (bannerText && setBannerText) {
      setBannerText(bannerText);
    }
  });

  let buttons = [];
  if (homeButton) {
    buttons.push(
      <div>
        <EuiButton iconType='arrowLeft' onClick={() => navigate('/dashboard')}>
          Back to Main Campaign Dashboard
        </EuiButton>
        <EuiSpacer />
      </div>
    );
  }
  buttons.push(...sidebarButtons);

  return (
    <EuiFlexGroup>
      <EuiFlexItem grow={1}>
        <Sidebar buttons={buttons} />
      </EuiFlexItem>
      <EuiFlexItem grow={9}>
        <EuiPanel paddingSize='m'>{pageBody}</EuiPanel>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

PageWithSidebar.propTypes = {
  /**
   * Text to assign to the banner/header
   */
  bannerText: PropTypes.any,

  /**
   * Function used to assign bannerText to the banner/header
   */
  setBannerText: PropTypes.func,

  /**
   * Data to display on the page's body
   */
  pageBody: PropTypes.object,

  /**
   * Array of buttons to display on the sidebar
   */
  sidebarButtons: PropTypes.arrayOf(PropTypes.object),

  /**
   * Toggle 'return to main campaign dashboard` button
   */
  homeButton: PropTypes.bool,
};

export default PageWithSidebar;
