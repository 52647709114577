import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';

/**
 * Component to display carrier text counts
 */
function CarrierChart({ title, height, series, labels }) {
  let options = {
    title: {
      text: title,
      align: 'center',
      style: {
        fontSize: '22px',
        fontWeight: 'bold',
      },
    },
    labels: labels,
    dataLabels: {
      enabled: true,
    },
    noData: {
      text: 'No Data',
      style: {
        fontSize: '22px',
        fontWeight: 'bold',
      },
    },
    chart: {
      type: 'bar',
      stacked: true,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      min: 0,
    },
  };

  return (
    <Chart
      type='bar'
      height={height}
      series={series}
      options={options}
      labels={labels}
    />
  );
}

CarrierChart.propTypes = {
  /**
   * Title to display on the chart
   */
  title: PropTypes.string,

  /**
   * Data to use in the chart
   */
  series: PropTypes.arrayOf(PropTypes.number).isRequired,

  /**
   * Labels for the data, should be the same length as series
   */
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * Height of chart
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CarrierChart;
