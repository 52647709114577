import React, { useContext, useState } from 'react';
import { EuiBasicTable, EuiConfirmModal, EuiLink } from '@elastic/eui';
import CreateBatchModal from 'components/CreateBatchModal/CreateBatchModal';
import PropTypes from 'prop-types';
import { SendTestTextModal } from 'components/SendTestTextModal/SendTestTextModal';
import DownloadIcon from 'components/widgets/DownloadIcon';
import { downloadSampleContacts } from 'api/SampleApi';
import { deleteSample } from 'api/SampleApi';
import { createErrorToast, createSuccessToast } from 'utils/toasts';
import ToastContext from 'contexts/ToastContext';
import UserContext from 'contexts/UserContext';
import { hasAccessToConfidentialProjects } from 'utils/privledges';

/**
 * Samples component
 */
function SampleList({
  samples = [],
  sampleType,
  setSamples = null,
  loadingSamples = false,
}) {
  const [createBatchModal, setCreateBatchModal] = useState();
  const [sampleToDelete, setSampleToDelete] = useState();
  const { addToast } = useContext(ToastContext);
  const { role } = useContext(UserContext);

  const columns = [
    {
      field: 'name',
      name: 'Sample Name',
      render: (name, sample) => {
        return (
          <>
            <EuiLink
              onClick={() => {
                setCreateBatchModal(
                  <CreateBatchModal
                    sample={sample}
                    closeModal={() => setCreateBatchModal()}
                    sampleType={sampleType}
                  />
                );
              }}
              disabled={!hasAccessToConfidentialProjects(role)}
            >
              {name}
            </EuiLink>
          </>
        );
      },
    },
    {
      field: 'validContacts',
      name: 'Valid Contacts',
    },
    {
      field: 'uploadedContactCount',
      name: 'Uploaded Contacts',
    },
    {
      field: 'scrubbedCompletesCount',
      name: 'Completes Scrubbed',
    },
    {
      field: 'scrubbedDnqsCount',
      name: 'DNQs Scrubbed',
    },
    {
      render: (sample) => {
        return (
          <>
            <EuiLink
              onClick={() => {
                setCreateBatchModal(
                  <SendTestTextModal
                    closeModal={() => setCreateBatchModal()}
                    sampleId={sample._id}
                    sampleType={sampleType}
                  />
                );
              }}
            >
              Send Test Text
            </EuiLink>
          </>
        );
      },
    },
    {
      name: 'Actions',
      width: '10%',
      actions: [
        {
          name: 'Download Contact Status',
          description: 'Download contacts status for the sample',
          render: (sample) => {
            return (
              <DownloadIcon
                request={downloadSampleContacts}
                requestParams={[sample._id]}
                filename={`${sample.name}_contact_status.csv`}
              />
            );
          },
        },
        {
          name: 'Delete',
          description: 'Delete sample',
          icon: 'trash',
          type: 'icon',
          color: 'danger',
          onClick: async (sample) => setSampleToDelete(sample),
        },
      ],
    },
  ];
  let deleteModal;
  if (sampleToDelete) {
    deleteModal = (
      <EuiConfirmModal
        title={`Delete ${sampleToDelete.name}`}
        onCancel={() => setSampleToDelete()}
        onConfirm={async () => {
          setSamples(samples.filter((e) => e._id !== sampleToDelete._id));
          let deleteResult = await deleteSample(sampleToDelete._id, sampleType);
          if (deleteResult) {
            addToast(createSuccessToast('Remove Sample', 'Deleted sample'));
          } else {
            addToast(
              createErrorToast('Remove Sample', 'Failed to delete sample')
            );
          }
          setSampleToDelete();
        }}
        cancelButtonText='Cancel'
        confirmButtonText='Delete'
        buttonColor='danger'
      >
        This action will permanently delete this sample.
      </EuiConfirmModal>
    );
  }

  return (
    <>
      <EuiBasicTable
        items={samples}
        columns={columns}
        noItemsMessage='No samples found.'
        loading={loadingSamples}
        hasActions={true}
      />
      {createBatchModal}
      {deleteModal}
    </>
  );
}

SampleList.propTypes = {
  /**
   * List of a Campaign's samples
   */
  samples: PropTypes.arrayOf(PropTypes.object),

  /**
   * Change the state of samples
   */
  setSamples: PropTypes.func,

  /**
   * state of loading samples
   */
  loadingSamples: PropTypes.bool,
};

export default SampleList;
