import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { EuiBasicTable, EuiFieldText } from '@elastic/eui';

/**
 * A Campaign's macro Macros and values
 */
function CampaignMacroList({ initialMacros, onChange }) {
  const KEYWORD_KEY = 'keyword';
  const VALUE_KEY = 'value';
  const DEFAULT_MACRO = { [KEYWORD_KEY]: '', [VALUE_KEY]: ''};

  const [ macros, setMacros ] = useState([...initialMacros, DEFAULT_MACRO]);

  const handleChange = (item, key, value) => {
    const index = macros.findIndex(e => e[KEYWORD_KEY] === item[KEYWORD_KEY] && e[VALUE_KEY] === item[VALUE_KEY]);
    const newMacros = [...macros];
    newMacros[index] = {
      ...item,
      [key]: value
    };

    setMacros(newMacros);
    onChange({ index: index, macro: {...newMacros[index]} });
  };

  useEffect(() => {
    if (JSON.stringify(macros[macros.length - 1]) !== JSON.stringify(DEFAULT_MACRO)) {
      setMacros(oldMacros => [...oldMacros, DEFAULT_MACRO])
    }
  }, [macros])

  const actions = [
    {
      name: 'Delete',
      description: 'Delete',
      icon: 'trash',
      type: 'icon',
      color: 'danger',
      onClick: (macroData) => {
        setMacros(macros.filter(data => data[KEYWORD_KEY] !== macroData[KEYWORD_KEY]))
      },
    },
  ]
  const columns = [
    {
      field: KEYWORD_KEY,
      name: 'Invite Macro/Keywords',
      render: (value, item) => (
        <EuiFieldText
          value={value}
          onChange={(e) => handleChange(item, KEYWORD_KEY, e.target.value)}
        />
      ),
    },
    {
      field: VALUE_KEY,
      name: 'Value',
      render: (value, item) => (
        <EuiFieldText
          value={value}
          onChange={(e) => handleChange(item, VALUE_KEY, e.target.value)}
        />
      ),
    },
    {
      name: 'Delete',
      width: '10%',
      actions: actions,
    }
  ];

  return (
    <>
      <EuiBasicTable
        items={macros}
        columns={columns}
        noItemsMessage='No macros'
      />
    </>
  );
}

CampaignMacroList.propTypes = {
  /**
   * Macro data changed function
   */
  onChange: PropTypes.func.isRequired,

  /**
   * Prefilled form data
   */
  initialMacros: PropTypes.arrayOf(PropTypes.shape({
    // Macro keyword to replace in the invite
    keyword: PropTypes.string,

    // Value to use in place of the macro
    value: PropTypes.string,
  }))
};

export default CampaignMacroList;
