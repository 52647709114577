import AppApi from 'services/appApi';

/**
 * Get a list of usernames from the server
 * @returns {Array<String>} A list of usernames
 */
async function getAllUsers() {
  try {
    let res = await AppApi.get('/admin/getAllUsers');
    if (res.status !== 200) {
      throw res;
    }
    return res.data.users;
  } catch (error) {
    console.error(error);
    return [];
  }
}

/**
 * Create a user with a name, password, and role
 * @param {Object} userData An object with name, password, and role
 * @returns {Bool} Request success status
 */
async function createUser(userData) {
  try {
    let res = await AppApi.post('/admin/registerUser', userData);
    if (res.status !== 201) {
      throw res;
    }
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

/**
 * Delete a user based on their username
 * @param {String} username A username to delete
 * @returns {Bool} Request success status
 */
async function deleteUser(username) {
  try {
    let res = await AppApi.delete('/admin/deleteUser', {
      data: { username },
    });
    if (res.status !== 200) {
      throw res;
    }
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export { getAllUsers, createUser, deleteUser };
