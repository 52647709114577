import { EuiSelect } from '@elastic/eui';
import { getCampaignMessages } from 'api/CampaignMessagesApi';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Select Invite Message component
 */
function SelectInvite({ onChange, campaignId, name, inviteType }) {
  const [loadingInvites, setIsLoadingInvites] = useState();
  const [inviteMessages, setInviteMessages] = useState([]);
  const [selected, setSelected] = useState();

  useEffect(() => {
    const loadCampaignInvites = async () => {
      setIsLoadingInvites(true);
      let invites = await getCampaignMessages(campaignId, inviteType);

      if (inviteType === 'registered') {
        invites = invites.registered;
      } else {
        invites = invites.unregistered;
      }

      invites = invites.map((inv) => {
        return {
          text: inv.name,
          value: inv._id,
          id: inv._id,
          testcount: inv.testCount,
          type: inv.type,
        };
      });
      setInviteMessages(invites);
      setIsLoadingInvites(false);
    };
    loadCampaignInvites();
  }, []);

  return (
    <EuiSelect
      name={name}
      isLoading={loadingInvites}
      options={inviteMessages}
      value={selected}
      hasNoInitialSelection={true}
      onChange={(e) => {
        setSelected(e.target.value);
        let selectedInvite = inviteMessages[e.target.selectedIndex - 1];
        selectedInvite._id = selectedInvite.value;
        onChange(selectedInvite);
      }}
    />
  );
}

SelectInvite.propTypes = {
  /**
   * Function called when a new Invite is selected
   *  @param {Event} e EuiSelect onChange event
   */
  onChange: PropTypes.func.isRequired,

  /**
   * ID of a campaign to get Invite messages for
   */
  campaignId: PropTypes.string.isRequired,

  /**
   * Name of the element
   */
  name: PropTypes.string,
};

export default SelectInvite;
