import { EuiEmptyPrompt, EuiImage, EuiText } from '@elastic/eui';
import { useContext, useState } from 'react';
import authenticateUser from 'api/LoginApi';
import LoginForm from 'components/LoginForm/LoginForm';
import useFormData from 'hooks/useFormData';
import ToastContext from 'contexts/ToastContext';
import { createErrorToast, createSuccessToast } from 'utils/toasts';

/**
 * The login screen
 * @param {function} setToken A function passed down from App.js to allow
 *    updates to the state as we get tokens.
 */
function LoginScreen({ setTokens, setRole, setUsername }) {
  const [formData, setFormData] = useFormData({
    username: '',
    password: '',
    twofa: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [show2FAField, setShow2FAField] = useState('none');
  const { addToast } = useContext(ToastContext);
  const TOAST_TITLE = 'Login';

  const handleSubmit = async (e) => {
    e.preventDefault();

    // ensure fields are filled out
    if (!formData.username && !formData.password) {
      addToast(
        createErrorToast(
          TOAST_TITLE,
          'Please enter a username and password to continue.'
        )
      );
      return;
    }
    if (!formData.username) {
      addToast(
        createErrorToast(TOAST_TITLE, 'Please enter a username to continue.')
      );
      return;
    }
    if (!formData.password) {
      addToast(
        createErrorToast(TOAST_TITLE, 'Please enter a password to continue.')
      );
      return;
    }

    // check account auth status
    setIsLoading(true);
    const authResponse = await authenticateUser(formData);

    if (!authResponse) {
      addToast(
        createErrorToast(TOAST_TITLE, 'Invalid username and/or password.')
      );
    } else if (authResponse.status === 203) {
      addToast(
        createSuccessToast(TOAST_TITLE, 'Enter Emailed Verification Code.')
      );
      setShow2FAField('block');
    } else {
      setRole(authResponse.data.role);
      setUsername(formData.username);
      setTokens(authResponse.data);
    }

    setIsLoading(false);
  };

  return (
    <EuiEmptyPrompt
      title={
        <>
          {process.env.REACT_APP_LOGO_IMAGE ? (
            <EuiImage
              size='l'
              src={
                process.env.PUBLIC_URL + '/' + process.env.REACT_APP_LOGO_IMAGE
              }
            />
          ) : (
            <EuiText>
              <h1>{process.env.REACT_APP_PLATFORM_NAME}</h1>
            </EuiText>
          )}
        </>
      }
      body={
        <>
          <LoginForm
            handleSubmit={handleSubmit}
            setFormData={setFormData}
            isLoading={isLoading}
            show2FAField={show2FAField}
          />
        </>
      }
    />
  );
}

export default LoginScreen;
