import AppApi from 'services/appApi';

/**
 * Edit campaign's single message.
 *
 * @param {String} campaignId The ID of the project to edit
 * @param {String} name Name of the invite message
 * @param {String} type Type of invite
 * @param {String} message Message to save to a campaign
 * @param {File} mms MMS file to attach to invite
 * @param {String} inviteId ID of the invite to edit. null if creating new invite
 * @param {String} mediaUrl URL code of MMS
 * @returns {Bool} Success status
 */
async function updateCampaignMessage(
  campaignId,
  name,
  type,
  message,
  mms,
  inviteId = null,
  isRegisteredMessage,
  mediaUrl
) {
  try {
    let formData = new FormData();
    formData.append('campaignId', campaignId);
    formData.append('inviteName', name);
    formData.append('type', type);
    formData.append('message', message);
    formData.append('isRegisteredMessage', isRegisteredMessage);
    formData.append('mediaUrl', mediaUrl);

    if (inviteId) {
      formData.append('inviteId', inviteId);
    }

    if (mms) {
      formData.append('mms', mms, mms.name);
    }

    let result = await AppApi.post('/saveCampaignMessage', formData);
    if (result.status === 200) {
      return true;
    }
    throw result;
  } catch (error) {
    console.error(error);
    return false;
  }
}

/**
 * Get the list of invite messages for a campaign.
 * @param {String} campaignId A campaign's ID
 */
async function getCampaignMessages(campaignId) {
  try {
    let res = await AppApi.get('/getCampaignMessages', {
      params: {
        campaignId: campaignId,
      },
    });

    if (res.status !== 200) {
      throw res;
    }

    return res.data.messages;
  } catch (error) {
    return [];
  }
}

/**
 * Delete Campaign Message
 * @param {String} campaignId A campaign's ID
 * @param {String} messageId An message's ID
 * @param {Boolean} isRegisteredMessage Registered Message
 */
async function deleteMessage(campaignId, messageId, isRegisteredMessage) {
  try {
    await AppApi.delete('/deleteMessage', {
      data: { projectId: campaignId, messageId, isRegisteredMessage },
    });
    return true;
  } catch (err) {
    return false;
  }
}

/**
 * Get the defaults for a message
 *
 * @param {Boolean} isRegistered Default Registered/Unregistered invite
 * @returns {Object} Default invite settings
 */
async function getDefaultMessage(isRegistered) {
  try {
    let res = await AppApi.get('/getDefaultMessage', {
      params: { isRegistered },
    });
    return res.data;
  } catch (error) {
    console.error(error);
  }

  return {};
}
export {
  updateCampaignMessage,
  getCampaignMessages,
  deleteMessage,
  getDefaultMessage,
};
