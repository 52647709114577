import AppApi from 'services/appApi';

/**
 * Download a list of numbers and the number of times we contacted them.
 *
 * @param {Date} [startDate=null] - Starting day of range
 * @param {Date} [endDate=null] - Ending day of range
 * @returns {Buffer} Subscriber file data
 */
async function downloadDupTimes(startDate = null, endDate = null) {
  try {
    let res = await AppApi.get('/downloadDupTimes', {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    });

    if (res.status !== 200) {
      throw res;
    }

    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export { downloadDupTimes };
