import { useState, useContext } from 'react';
import {
  EuiAccordion,
  EuiBasicTable,
  EuiConfirmModal,
  htmlIdGenerator,
} from '@elastic/eui';
import { deleteMessage } from 'api/CampaignMessagesApi';
import CampaignContext from '../../contexts/CampaignContext';
import { createErrorToast, createSuccessToast } from 'utils/toasts';
import ToastContext from 'contexts/ToastContext';
import SaveInviteModal from 'components/SaveInviteModal/SaveInviteModal';

/**
 * A Campaign's home screen message list
 */
function CampaignMessageList({ invites, isRegisteredMessage, setInvites }) {
  const [modal, setModal] = useState();
  const { campaign } = useContext(CampaignContext);
  const idGenerator = htmlIdGenerator('inviteAccordian');
  const { addToast } = useContext(ToastContext);

  const showEditInviteModal = (invite) => {
    setModal(
      <SaveInviteModal
        invite={invite}
        isRegisteredMessage={isRegisteredMessage}
        type='update'
        onClose={() => setModal()}
      />
    );
  };

  const showDeleteInviteModal = (invite) => {
    setModal(
      <EuiConfirmModal
        title={`Delete ${invite.name}`}
        onCancel={() => setModal()}
        onConfirm={async () => {
          setInvites(invites.filter((inv) => inv._id !== invite._id));

          let deleteResult = await deleteMessage(
            campaign.projectId,
            invite._id,
            isRegisteredMessage
          );

          if (deleteResult) {
            addToast(createSuccessToast('Remove Invite', 'Deleted Invite'));
          } else {
            addToast(
              createErrorToast('Remove Invite', 'Failed to delete invite')
            );
          }
          setModal();
        }}
        cancelButtonText='Cancel'
        confirmButtonText='Delete'
        buttonColor='danger'
      >
        This action will permanently delete this invite message.
      </EuiConfirmModal>
    );
  };

  let columns = [
    {
      field: 'name',
      name: 'Name',
      render: (name, invite) => {
        return (
          <EuiAccordion
            id={idGenerator()}
            buttonContent={name}
            initialIsOpen={true}
          >
            {invite.message}
          </EuiAccordion>
        );
      },
    },
    {
      name: 'Actions',
      width: '15%',
      actions: [
        {
          name: 'Edit',
          description: 'Edit Invite',
          icon: 'pencil',
          type: 'icon',
          color: 'primary',
          onClick: (invite) => showEditInviteModal(invite),
        },
        {
          name: 'Delete',
          description: 'Delete Invite',
          icon: 'trash',
          type: 'icon',
          color: 'danger',
          onClick: (invite) => showDeleteInviteModal(invite),
        },
      ],
    },
  ];

  return (
    <>
      <EuiBasicTable
        items={invites}
        columns={columns}
        noItemsMessage='No invite messages found.'
      />
      {modal}
    </>
  );
}

export default CampaignMessageList;
