import React, { useContext } from 'react';
import { EuiPanel, EuiSpacer, EuiText } from '@elastic/eui';
import { zoneToCode } from 'utils/zone';
import CampaignContext from 'contexts/CampaignContext';

/**
 * Display a campaign's two letter zone
 */
function CampaignZone() {
  const { campaign } = useContext(CampaignContext);
  return (
    <EuiPanel hasShadow={false}>
      <EuiText textAlign='center'>
        <h2>State</h2>
        <EuiSpacer size='l' />
        <EuiText>
          <h1>{zoneToCode(campaign.zone)}</h1>
          <p>{campaign.zone}</p>
        </EuiText>
      </EuiText>
    </EuiPanel>
  );
}

export default CampaignZone;
