import { EuiFieldText } from '@elastic/eui';
import PropTypes from 'prop-types';
import { useState } from 'react';

/** Search bar for filtering projects. */
function ProjectSearchBar({ onChange }) {
  const [query, setQuery] = useState('');
  const handleInput = (query) => {
    setQuery(query);
    onChange(query);
  };

  return (
    <>
      <EuiFieldText
        value={query}
        placeholder='e.g. name:1234ABCD zone:California'
        onChange={(e) => handleInput(e.target.value)}
      />
    </>
  );
}

ProjectSearchBar.propTypes = {
  /**
   * Action to perform for a query
   */
  onChange: PropTypes.func,
};

export default ProjectSearchBar;
