import React, { useContext, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { getSamples } from 'api/SampleApi';
import CampaignSampleManager from 'components/CampaignSampleManager/CampaignSampleManager';
import CampaignContext from 'contexts/CampaignContext';

/**
 * View or add samples to a campaign
 */
function CampaignSamples() {
  const [registeredSamples, setRegisteredSamples] = useState([]);
  const [unregisteredSamples, setUnregisteredSamples] = useState([]);

  const [loadingSamples, setLoadingSamples] = useState();

  const { id } = useParams();
  const { campaign } = useContext(CampaignContext);

  useEffect(() => {
    const loadSamples = async () => {
      setLoadingSamples(true);
      let response = await getSamples(id);

      setRegisteredSamples(response.registered);
      setUnregisteredSamples(response.unregistered);

      setLoadingSamples(false);
    };
    loadSamples();
  }, []);

  const onUploadSample = async () => {
    let response = await getSamples(id);

    setRegisteredSamples(response.registered);
    setUnregisteredSamples(response.unregistered);
  };

  return (
    <>
      {campaign.isRegistered && (
        <CampaignSampleManager
          samples={registeredSamples}
          setSamples={setRegisteredSamples}
          loadingSamples={loadingSamples}
          onUploadSample={onUploadSample}
          title='Registered Contacts (Samples)'
          sampleType='registered'
        />
      )}
      <CampaignSampleManager
        samples={unregisteredSamples}
        setSamples={setUnregisteredSamples}
        loadingSamples={loadingSamples}
        onUploadSample={onUploadSample}
        title='Unregistered Contacts (Samples)'
        sampleType='unregistered'
      />
    </>
  );
}

export default CampaignSamples;
