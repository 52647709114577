import React, { useContext, useState } from 'react';
import {
  EuiButton,
  EuiEmptyPrompt,
  EuiFilePicker,
  EuiListGroup,
  EuiListGroupItem,
  EuiText,
} from '@elastic/eui';
import { tnLookup } from 'api/servicesApi';
import { createErrorToast, createSuccessToast } from 'utils/toasts';
import ToastContext from 'contexts/ToastContext';

/**
 * Component to lookup TN information
 */
function TnLookup() {
  const [uploadedFile, setUploadedFile] = useState();
  const { addToast } = useContext(ToastContext);
  const TOAST_TITLE = 'TN Lookup';

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let success = await tnLookup(uploadedFile);

      if (!success) {
        throw Error('An error occurred while processing the TNs');
      }

      addToast(
        createSuccessToast(
          TOAST_TITLE,
          'An email containing the TN information will be sent in the next 5-10 minutes.'
        )
      );
    } catch (error) {
      addToast(
        createErrorToast(
          TOAST_TITLE,
          'An error occurred while processing the TNs'
        )
      );
    }
  };

  return (
    <>
      <EuiEmptyPrompt
        template='centeredContent'
        title={<h2>TN Lookup</h2>}
        body={
          <EuiFilePicker
            name='tn lookup'
            onChange={(e) => {
              setUploadedFile(e[0] ?? []);
            }}
            accept={['.txt', '.csv']}
          />
        }
        footer={
          <EuiButton type='submit' onClick={handleSubmit}>
            Submit
          </EuiButton>
        }
      />
      <EuiText>
        <h3>File Formatting</h3>
      </EuiText>
      <EuiListGroup maxWidth={false}>
        <EuiListGroupItem label='1. File type is a .csv (e.g. tns_to_lookup.csv).' />
        <EuiListGroupItem label='2. The column with the header "phonef" will be read.' />
        <EuiListGroupItem label='3. Results will be emailed to you upon completion.' />
      </EuiListGroup>
    </>
  );
}

export default TnLookup;
