import { useEffect, useState } from 'react';
import { EuiButton } from '@elastic/eui';
import PageWithSidebar from 'templates/PageWithSidebar/PageWithSidebar';
import ShortUrlTool from 'components/ShortUrlTool/ShortUrlTool';
import PropTypes from 'prop-types';
import { downloadSubscribers, downloadOptIns } from 'api/servicesApi';
import DownloadButton from 'components/widgets/DownloadButton';
import { getGlobalDncList } from 'api/DNCApi';
import FilterDnc from 'components/FilterDnc/FilterDnc';
import UploadDnc from 'components/UploadDnc/UploadDnc';
import CarrierInfo from 'components/CarrierInfo/CarrierInfo';
import TnLookup from 'components/TnLookup/TnLookup';
import DupTimes from 'components/DupTimes/DupTimes';
import ContactHistory from 'components/ContactHistory/ContactHistory';

/**
 * Tools page
 */
function Tools({ setBannerText }) {
  const [mainPage, setMainPage] = useState();

  let shortenUrlPage = <ShortUrlTool />;
  let addToDncPage = <UploadDnc />;
  let dncFilterPage = <FilterDnc />;
  let carrierInfoPage = <CarrierInfo />;
  let tnLookupPage = <TnLookup />;
  let dupTimesPage = <DupTimes />;
  let ContactHistoryPage = <ContactHistory />;

  const buttons = [
    <EuiButton
      onClick={() => {
        setMainPage(shortenUrlPage);
      }}
    >
      URL Shortener
    </EuiButton>,
    <DownloadButton
      request={downloadSubscribers}
      text='Download Subscribers'
      filename='subscribers'
    />,
    <DownloadButton
      request={downloadOptIns}
      text='Download Campaign Opt-Ins'
      filename='opt-ins'
    />,
    <DownloadButton
      request={getGlobalDncList}
      text='Download Global DNC List'
      filename='master-dnc'
    />,
    <EuiButton
      onClick={() => {
        setMainPage(addToDncPage);
      }}
    >
      Upload DNC list
    </EuiButton>,
    <EuiButton
      onClick={() => {
        setMainPage(dncFilterPage);
      }}
    >
      Filter Out DNCs
    </EuiButton>,
    <EuiButton
      onClick={() => {
        setMainPage(carrierInfoPage);
      }}
    >
      Carrier Info
    </EuiButton>,
    <EuiButton
      onClick={() => {
        setMainPage(tnLookupPage);
      }}
    >
      TN Lookup
    </EuiButton>,
    <EuiButton
      onClick={() => {
        setMainPage(dupTimesPage);
      }}
    >
      DUP Times
    </EuiButton>,
    <EuiButton
      onClick={() => {
        setMainPage(ContactHistoryPage);
      }}
    >
      Get Contact history
    </EuiButton>,
  ];

  useEffect(() => {
    setMainPage(shortenUrlPage);
  }, []);

  return (
    <PageWithSidebar
      bannerText='Tools'
      setBannerText={setBannerText}
      sidebarButtons={buttons}
      pageBody={mainPage}
    />
  );
}

Tools.propTypes = {
  /**
   * Function to set the page header
   *
   * @param {any} bannerText data to assign to the banner's header
   */
  setBannerText: PropTypes.func.isRequired,
};
export default Tools;
