import {
  copyToClipboard,
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import useFormData from 'hooks/useFormData';
import { generateShortUrl } from 'api/ShortUrlApi';
import { useContext, useState } from 'react';
import ToastContext from 'contexts/ToastContext';
import { createErrorToast } from 'utils/toasts';

/**
 * The form that takes a URL and returns a shortened URL
 */
function ShortUrlTool() {
  const [formData, setFormData] = useFormData({
    longUrl: '',
  });
  const [shortUrl, setShortUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useContext(ToastContext);
  const TOAST_TITLE = 'Short URL';

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.longUrl) {
      addToast(createErrorToast(TOAST_TITLE, 'Please enter a url to shorten.'));
      return;
    }

    setIsLoading(true);
    const generatedShortUrl = await generateShortUrl(formData.longUrl);
    setIsLoading(false);
    if (generatedShortUrl) {
      setShortUrl(generatedShortUrl);
    } else {
      addToast(
        createErrorToast(
          TOAST_TITLE,
          'Error occured when generating Short URL.\nLong URL may have already been used.'
        )
      );
    }
  };

  return (
    <>
      <EuiForm onSubmit={handleSubmit} component='form' className='short-url'>
        <EuiFormRow>
          <EuiFieldText
            name='longUrl'
            placeholder='https://example.com'
            onChange={setFormData}
          />
        </EuiFormRow>
        <EuiButton type='submit' isLoading={isLoading}>
          Generate short URL
        </EuiButton>
      </EuiForm>
      <EuiSpacer />
      <EuiText>Short Url: {shortUrl}</EuiText>
      <EuiSpacer />
      <EuiButton
        onClick={() => {
          copyToClipboard(shortUrl);
        }}
      >
        Copy to clipboard
      </EuiButton>
    </>
  );
}

export default ShortUrlTool;
