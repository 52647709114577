import AppApi from 'services/appApi';
import { FORMATTED_SCRUB_REASON } from 'utils/constants';

/**
 * Retrieve a campaign's contact list.
 *
 * @param {String} campaignId The ID used to identify a campaign
 * @returns {Array<Object>} Campaign's list of contacts
 */
async function getContacts(campaignId) {
  try {
    let res = await AppApi.get(`/contactsForCampaign/${campaignId}`);

    if (res.status !== 200) {
      throw res;
    }
    return res.data.contacts;
  } catch (error) {
    return [];
  }
}

/**
 * Retrieve a campaign's contact list as a csv file
 *
 * @param {String} campaignId The ID used to identify a campaign
 * @returns {File} Campaign's list of contacts as a csv file
 */
async function generateContactsCsv(contacts) {
  let csvString =
    'First Name, Last Name, Token, Phone Number, Unique ID, Contacted, DNC, Scrub Reason\r\n';
  for (let contact of contacts) {
    let data = [
      contact.firstname,
      contact.lastname,
      contact.token,
      contact.tn,
      contact.uniqueId,
      String(contact.contacted ?? 'False'),
      String(contact.dnc ?? 'False'),
      String(FORMATTED_SCRUB_REASON[contact.scrubReason] ?? ''),
    ];

    csvString += data.join(',') + '\r\n';
  }

  return csvString;
}

export { getContacts, generateContactsCsv };
