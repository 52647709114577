import { useState, useEffect, useContext } from 'react';
import { EuiButton } from '@elastic/eui';
import { useNavigate } from 'react-router-dom';
import UserContext from 'contexts/UserContext';
import {
  CreateUserModal,
  DeleteUserModal,
} from 'components/AdminModal/AdminModal';
import PageWithSidebar from 'templates/PageWithSidebar/PageWithSidebar';
import PropTypes from 'prop-types';

/**
 * The admin actions screen
 */
function AdminScreen({ setBannerText }) {
  const navigate = useNavigate();
  const { role } = useContext(UserContext);
  const [curModal, setModal] = useState();
  const closeModal = () => setModal();

  const showCreateUserModal = () => {
    setModal(<CreateUserModal closeModal={closeModal} />);
  };
  const showDeleteUserModal = () => {
    setModal(<DeleteUserModal closeModal={closeModal} />);
  };

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/dashboard');
    }
  }, []);

  return (
    <PageWithSidebar
      bannerText='Admin Dashboard'
      setBannerText={setBannerText}
      pageBody={
        <div className='user-actions'>
          <EuiButton onClick={() => showCreateUserModal()}>
            Create User
          </EuiButton>
          <EuiButton onClick={() => showDeleteUserModal()}>
            Delete User
          </EuiButton>
          {curModal}
        </div>
      }
    />
  );
}

AdminScreen.propTypes = {
  /**
   * banner text hook
   * @param {string} bannerText The string to set the banner text to
   */
  setBannerText: PropTypes.func.isRequired,
};

export default AdminScreen;
