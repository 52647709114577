import { findSubstring } from './strings';

/**
 * Validate an invite
 *
 * @param {String} message - Invite message
 * @param {Boolean} isRegistered - true if registered invite
 * @returns {String} Empty string on valid message. Otherwise error message for user.
 */
export function validateMessage(message, isRegistered) {
  const requiredTerms = ['STOP'];
  for (const term of requiredTerms) {
    if (!findSubstring(message, term, false)) {
      return `Must include ${term} in invite message`;
    }
  }

  if (isRegistered) {
    const registeredTerms = [process.env.REACT_APP_PLATFORM_NAME];
    for (const term of registeredTerms) {
      if (!findSubstring(message, term, false)) {
        return `Must include ${term} in invite message`;
      }
    }
  }

  return '';
}
