import React from 'react';
import {
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiFieldText,
  EuiFieldPassword,
} from '@elastic/eui';

/**
 * The login form
 * @param {function} handleSubmit The submit handler for logging in.
 * @param {function} setFormData Updates the form state when a change occurs.
 */
function LoginForm({ handleSubmit, setFormData, isLoading, show2FAField }) {
  return (
    <EuiForm
      onSubmit={handleSubmit}
      component='form'
      className='login-container'
    >
      <EuiFormRow>
        <EuiFieldText
          name='username'
          placeholder='Username'
          onChange={setFormData}
        />
      </EuiFormRow>
      <EuiFormRow>
        <EuiFieldPassword
          name='password'
          placeholder='Password'
          type='password'
          onChange={setFormData}
        />
      </EuiFormRow>
      <EuiFormRow style={{ display: show2FAField }}>
        <EuiFieldText
          name='twofa'
          placeholder='Verification Code'
          onChange={setFormData}
        />
      </EuiFormRow>
      <EuiButton type='submit' isLoading={isLoading} fill>
        Log In
      </EuiButton>
    </EuiForm>
  );
}

export default LoginForm;
