import * as React from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

/**
 * Blocks a given navigation request, if shouldBlock is true.
 * @param {Function} blocker - Function to call to apply blocking action
 * @param {Boolean} shouldBlock - Should the navigation request be blocked
 */
export function useBlocker(blocker, shouldBlock = true) {
  const { navigator } = React.useContext(NavigationContext);

  React.useEffect(() => {
    if (!shouldBlock) {
      return;
    }
    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, shouldBlock]);
}

/**
 * Prompt to display when blocking a navigation request
 * @param {String} message - Message to display
 * @param {Boolean} shouldBlock - Should the navigation request be blocked
 * @param {Function} action - An action to perform on block
 */
export function usePrompt(message, shouldBlock = true, action = null) {
  const blocker = React.useCallback(
    async (tx) => {
      if (window.confirm(message)) {
        if (action) {
          await action();
        }
        tx.retry();
      }
    },
    [message]
  );

  useBlocker(blocker, shouldBlock);
}
