import { useNavigate } from 'react-router-dom';
import { EuiBasicTable, EuiConfirmModal, EuiLink } from '@elastic/eui';
import PropTypes from 'prop-types';
import { deleteCampaign } from 'api/CampaignApi';
import { useContext, useState, useEffect } from 'react';
import { createErrorToast, createSuccessToast } from 'utils/toasts';
import ToastContext from 'contexts/ToastContext';
import { getCampaigns } from 'api/CampaignApi';
import ProjectSearchBar from 'components/ProjectSearchBar/ProjectSearchBar';

/** Displays a list of campaigns. */
function CampaignList({ campaignType }) {
  const [campaignToDelete, setCampaignToDelete] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  const [filteredProjects, setFilteredProjects] = useState([]);
  const [pagination, setPagination] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);

  const actions = [
    {
      name: 'Delete',
      description: 'Delete this campaign',
      icon: 'trash',
      type: 'icon',
      color: 'danger',
      onClick: (campaign) => setCampaignToDelete(campaign),
    },
  ];

  const columns = [
    {
      field: 'name',
      name: 'Campaign Name',
      render: (name, item) => {
        return (
          <EuiLink
            onClick={() => {
              navigate(`/campaign/${item.projectId}`, {
                state: {
                  campaignName: name,
                  surveyLongUrl: item.surveyLongUrl,
                  zone: item.zone,
                },
              });
            }}
          >
            {name}
          </EuiLink>
        );
      },
    },
    {
      field: 'updatedAt',
      name: 'Last Modified',
      render: (date) => {
        let formattedDate = new Date(date);
        return (
          <span>
            {formattedDate.toLocaleString('en-US', {
              timeZone: 'America/Toronto',
            })}{' '}
            EST
          </span>
        );
      },
    },
    {
      field: 'clicks',
      name: 'Clicks',
    },
    {
      field: 'size',
      name: 'Size',
    },
    {
      field: 'sent',
      name: 'Sent',
    },
    {
      field: 'done',
      name: 'Done',
      render: (date) => {
        return (date * 100).toFixed(2) + '%';
      },
    },
    {
      name: 'Actions',
      actions,
    },
  ];

  /**
   * Delete a campaign
   * @param {String} campaignId
   */
  async function removeCampaign(campaignId) {
    let result = await deleteCampaign(campaignId);
    if (result) {
      addToast(createSuccessToast('Remove Campaign', 'Deleted project'));
      window.location.reload();
    } else {
      addToast(createErrorToast('Remove Campaign', 'Failed to delete project'));
    }
  }

  const campaignSort = (a, b) => {
    if (a.updatedAt < b.updatedAt) {
      return 1;
    }
    if (a.updatedAt > b.updatedAt) {
      return -1;
    }
    return 0;
  };

  let deleteModal;
  if (campaignToDelete) {
    deleteModal = (
      <EuiConfirmModal
        title={`Delete ${campaignToDelete.name}`}
        onCancel={() => setCampaignToDelete()}
        onConfirm={() => removeCampaign(campaignToDelete.projectId)}
        cancelButtonText='Cancel'
        confirmButtonText='Delete'
        buttonColor='danger'
      >
        This action will permanently delete this campaign.
      </EuiConfirmModal>
    );
  }

  useEffect(() => {
    const fetchCampaigns = async () => {
      setIsLoading(true);
      let campaignData = await getCampaigns(
        searchQuery,
        pageIndex,
        pageSize,
        campaignType
      );
      setIsLoading(false);
      campaignData.campaigns.sort(campaignSort);
      setFilteredProjects(campaignData.campaigns);
      setPagination({
        pageIndex,
        pageSize,
        totalItemCount: campaignData.totalCount,
        pageSizeOptions: [50, 100, 200],
      });
    };

    fetchCampaigns();
  }, [pageIndex, pageSize, searchQuery, campaignType]);

  const onTableChange = ({ page = {} }) => {
    setPageIndex(page.index);
    setPageSize(page.size);
  };

  if (pagination === null) {
    return <div>Loading...</div>;
  }

  /**
   * Filter projects based on a query
   *
   * @param {String} query - A string for the search
   */
  const handleProjectSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <div className='campaign-list'>
      <ProjectSearchBar onChange={handleProjectSearch} />
      <EuiBasicTable
        items={filteredProjects}
        columns={columns}
        noItemsMessage='No campaigns found.'
        loading={isLoading}
        pagination={pagination}
        onChange={onTableChange}
      />
      {deleteModal}
    </div>
  );
}

CampaignList.propTypes = {
  /**
   * List of campaigns
   */
  campaignList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CampaignList;
