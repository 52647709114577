import { EuiText } from '@elastic/eui';
import PropTypes from 'prop-types';
import './TestTextMessagePreview.css';

/**
 * Test Text preview component
 */
function TestTextMessagePreview({ message }) {
  // Hyperlink all urls starting w/ http or https
  message = message.replace(
    /(https?:\/\/[a-zA-Z0-9_\-./:]*)/gm,
    '<a href="$1">$1</a>'
  );

  let innerText = {
    __html: message,
  };

  return (
    <EuiText
      className='textPreview'
      size='s'
      dangerouslySetInnerHTML={innerText}
    />
  );
}

TestTextMessagePreview.propTypes = {
  /**
   * Message to display
   */
  message: PropTypes.string.isRequired,
};
export default TestTextMessagePreview;
