import AppApi from 'services/appApi';

/**
 * Retrieve a list of all registered campaigns for each state
 *
 * @returns {Object} Registered campaigns for each state
 */
async function getRegisteredCampaigns() {
  try {
    let result = await AppApi.get('/getRegisteredCampaigns');
    return result.data;
  } catch (error) {
    console.error(error);
    return {};
  }
}

/**
 * Get the number of TNs that we have for a registered campaign
 *
 * @param {String} registeredCampaignId Registered campaign ID
 * @returns {Number} TN Count for a registered campaign
 */
async function getUpdatedRegisteredTnCount(registeredCampaignId) {
  try {
    let response = await AppApi.get('/getRegisteredTnCount', {
      params: { registeredCampaignId: registeredCampaignId },
    });
    return response.data.count;
  } catch (error) {
    console.error(error);
  }

  return 0;
}
export { getRegisteredCampaigns, getUpdatedRegisteredTnCount };
