const { default: AppApi } = require('services/appApi');

/**
 * Create a batch of batchSize from a sample.
 *
 * @param {String} projectId Project ID
 * @param {String} sampleId Sample ID
 * @param {Number} batchSize Size of batch to create
 * @param {String} inviteId ID of an invite message
 * @param {String} telecom telecom provider to send the batch with
 * @returns {Bool} true on success
 */
async function createBatch(projectId, sampleId, batchSize, inviteId, telecom) {
  try {
    let batchForm = new FormData();
    batchForm.append('projectId', projectId);
    batchForm.append('sampleId', sampleId);
    batchForm.append('batchSize', batchSize);
    batchForm.append('inviteId', inviteId);
    batchForm.append('telecom', telecom);

    let result = await AppApi.post('/createNewBatch', batchForm);
    if (result.status !== 201) {
      throw result;
    }
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export { createBatch };
