import { EuiPanel, EuiText, EuiTimeline, EuiSplitPanel } from '@elastic/eui';
import React, { useContext } from 'react';
import CampaignContext from 'contexts/CampaignContext';

/**
 * Campaign activity component
 */
function CampaignActivity() {
  const { campaign } = useContext(CampaignContext);
  let activityItems = generateActivityItems(campaign.logs);

  return (
    <EuiPanel hasShadow={false} hasBorder={true}>
      <EuiText textAlign='center'>
        <h1>Activity</h1>
        <EuiTimeline items={activityItems} />
      </EuiText>
    </EuiPanel>
  );
}

/**
 * Create a list of valid items for the activity timeline
 *
 * @param {Array<Object>} logs Collection of logs with the format {type, msg, date}
 * @returns {Array<Object>} A collection to use as items with EuiTimeline
 */
function generateActivityItems(logs) {
  if (!(logs && Array.isArray(logs))) {
    return [];
  }

  logs.sort((a, b) => {
    if (a.date < b.date) {
      return 1;
    }
    if (a.date > b.date) {
      return -1;
    }
    return 0;
  });

  return logs.map((log) => {
    let content = (
      <>
        <EuiText>
          {log.msg} at{' '}
          {new Date(log.date).toLocaleString('en-US', {
            timeZone: 'America/Toronto',
          })}
        </EuiText>
      </>
    );

    if (log.additionalInfo) {
      content = (
        <EuiSplitPanel.Outer hasShadow={false} hasBorder={true}>
          <EuiSplitPanel.Inner>{content}</EuiSplitPanel.Inner>
          <EuiSplitPanel.Inner>
            {log.additionalInfo.split('\n').map((line) => (
              <p>{line}</p>
            ))}
          </EuiSplitPanel.Inner>
        </EuiSplitPanel.Outer>
      );
    }

    return {
      icon: getActivityIcon(log.type),
      children: content,
    };
  });
}

/**
 * Retrieves the icon name for a given activity log type.
 *
 * @param {String} type Type of activity
 * @returns {String} Eui icon type
 */
function getActivityIcon(type) {
  switch (type) {
    case 'Created':
      return 'indexOpen';
    case 'Error':
      return 'crossInACircleFilled';
    case 'Status Update':
      return 'flag';
    case 'Deleted':
      return 'trash';
    default:
      return 'pencil';
  }
}

export default CampaignActivity;
