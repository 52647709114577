import { useContext, useEffect, useState } from 'react';
import {
  EuiButton,
  EuiFlexGrid,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiSpacer,
  EuiConfirmModal,
  EuiText,
} from '@elastic/eui';
import { useNavigate, useParams } from 'react-router-dom';
import CampaignUrl from 'components/CampaignUrl/CampaignUrl';
import CampaignMessageManager from 'components/CampaignMessages/CampaignMessageManager';
import CampaignSamples from 'components/CampaignSamples/CampaignSamples';
import PageWithSidebar from 'templates/PageWithSidebar/PageWithSidebar';
import CampaignZone from 'components/CampaignZone/CampaignZone';
import CampaignDetailsModal from 'components/CampaignDetailsModal/CampaignDetailsModal';
import { getCampaignDNC } from 'api/DNCApi';
import DownloadButton from 'components/widgets/DownloadButton';
import PropTypes from 'prop-types';
import CampaignActivity from 'components/CampaignActivity/CampaignActivity';
import {
  getCampaign,
  archiveCampaign,
  getCampaignStats,
  downloadCompletesCsv,
  downloadDnqsCsv,
} from 'api/CampaignApi';
import CampaignStats from 'components/CampaignStats/CampaignStats';
import { isURL } from 'validator';
import CampaignContext from 'contexts/CampaignContext';
import UserContext from 'contexts/UserContext';
import { createErrorToast } from 'utils/toasts';
import ToastContext from 'contexts/ToastContext';
import { hasAccessToConfidentialProjects } from 'utils/privledges';

/** Main Campaign page for individual campaigns */
function CampaignDetails({ setBannerText }) {
  const [campaign, setCampaign] = useState({});
  const [sideBarButtons, setSideBarButtons] = useState([]);
  const navigate = useNavigate();
  const [curModal, setModal] = useState();
  const [pageDom, setPageDom] = useState();
  const closeModal = () => setModal();
  const [isLoading, setIsLoading] = useState(false);
  const [isCampaignInitialized, setIsCampaignInitialized] = useState(false);
  const { id } = useParams();
  const [campaignStatsData, setCampaignStats] = useState({});
  const { role } = useContext(UserContext);
  const { addToast } = useContext(ToastContext);
  const TOAST_TITLE = 'Campaign Details';

  /**
   * Archive a campaign
   */
  const handleCampaignStatusChange = () => {
    setModal(
      <EuiConfirmModal
        title={`Archive ${campaign.name}`}
        onCancel={() => setModal()}
        onConfirm={() => handleArchiveCampaign()}
        cancelButtonText='Cancel'
        confirmButtonText='Archive'
        buttonColor='danger'
      >
        This action cannot be reverted. Sample Contacts will be archived.
      </EuiConfirmModal>
    );
  };

  /**
   * Archive the campaign and it's contacts permanently
   */
  const handleArchiveCampaign = async () => {
    setIsLoading(true);

    let success = await archiveCampaign(campaign.projectId);

    setIsLoading(false);

    if (success) {
      setCampaign({
        ...campaign,
        isActive: false,
      });
      addToast(
        createErrorToast(TOAST_TITLE, 'Campaign status set to completed.')
      );
    } else {
      addToast(
        createErrorToast(TOAST_TITLE, 'Failed to change campaign status.')
      );
    }

    setModal();
  };

  const editCampaignButton = (
    <EuiButton
      isDisabled={!isCampaignInitialized}
      onClick={() =>
        setModal(
          <CampaignDetailsModal
            closeModal={closeModal}
            initFormData={{
              name: campaign.name,
              surveyLongUrl: campaign.surveyLongUrl,
              zone: campaign.zone,
              registeredCampaignId: campaign.registeredCampaignId,
            }}
            campaignId={campaign.projectId}
          />
        )
      }
    >
      Edit Campaign
    </EuiButton>
  );
  const masterContactListButton = (
    <EuiButton onClick={() => navigate('contacts')}>
      View Contact List
    </EuiButton>
  );

  // Download DNC (opt-out)
  const downloadCampaignDNCButton = (
    <DownloadButton
      request={getCampaignDNC}
      requestParams={[campaign.projectId]}
      text='Download Campaign DNC List'
      filename={`${campaign.projectId}_dnc_list.csv`}
      isDisabled={!isCampaignInitialized}
    />
  );

  // Download LS Completes for the survey
  const downloadCampaignCompletesButton = (
    <DownloadButton
      request={downloadCompletesCsv}
      requestParams={[campaign.projectId]}
      text='Download Completes'
      filename={`${campaign.projectId}_completes_list.csv`}
      isDisabled={!isCampaignInitialized}
    />
  );

  // Download LS DNQs for the survey
  const downloadCampaignDnqsButton = (
    <DownloadButton
      request={downloadDnqsCsv}
      requestParams={[campaign.projectId]}
      text='Download DNQs'
      filename={`${campaign.projectId}_dnqs_list.csv`}
      isDisabled={!isCampaignInitialized}
    />
  );

  let changeCampaignStatusButton = (
    <EuiButton
      onClick={handleCampaignStatusChange}
      isLoading={isLoading}
      disabled={!hasAccessToConfidentialProjects(role) || !campaign.isActive}
    >
      Mark Campaign Completed
    </EuiButton>
  );

  useEffect(() => {
    const loadCampaign = async () => {
      setCampaign(await getCampaign(id));
    };
    const loadCampaignStats = async () => {
      setCampaignStats(await getCampaignStats(id));
    };
    loadCampaign();
    loadCampaignStats();
    setIsCampaignInitialized(true);
  }, []);

  useEffect(() => {
    setSideBarButtons([
      editCampaignButton,
      masterContactListButton,
      downloadCampaignDNCButton,
      downloadCampaignCompletesButton,
      downloadCampaignDnqsButton,
      changeCampaignStatusButton,
    ]);
  }, [campaign]);

  useEffect(() => {
    const context = {
      campaign: campaign,
      setCampaign: setCampaign,
      campaignStatsData: campaignStatsData,
      validLongUrl: validateCampaignUrl(campaign.surveyLongUrl),
    };
    const pageBody = createPageBody(campaign);

    setPageDom(
      <div className='campaign-details-container'>
        <CampaignContext.Provider value={context}>
          <PageWithSidebar
            className='campaign-details-container'
            bannerText={
              <>
                <EuiIcon
                  type='dot'
                  size='l'
                  className='campaign-active'
                  color={campaign.isActive ? 'green' : 'grey'}
                />
                {campaign.name}
              </>
            }
            setBannerText={setBannerText}
            sidebarButtons={sideBarButtons}
            pageBody={pageBody}
          />
          {curModal}
        </CampaignContext.Provider>
      </div>
    );
  }, [campaign, campaignStatsData, curModal, sideBarButtons]);

  return pageDom;
}

/**
 * Create the campaign details page
 *
 * @returns {Object} The campaign details page
 */
function createPageBody(campaign) {
  return Object.keys(campaign).length ? (
    <div>
      {!campaign.isActive && (
        <EuiText color='danger'>
          <h1>Project has been archived</h1>
        </EuiText>
      )}
      <CampaignUrl />
      <EuiSpacer />
      <CampaignStats />
      <EuiSpacer />
      <EuiHorizontalRule />
      <EuiFlexGrid>
        <EuiFlexItem grow={false}>
          <CampaignZone />
        </EuiFlexItem>
        <EuiFlexItem grow={9}>
          <CampaignMessageManager />
        </EuiFlexItem>
      </EuiFlexGrid>
      <EuiSpacer />
      {campaign.isActive ? (
        <CampaignSamples />
      ) : (
        <EuiText color='danger'>
          <h1>Samples unavailable for archived projects</h1>
        </EuiText>
      )}
      <EuiSpacer />
      <CampaignActivity />
    </div>
  ) : (
    <></>
  );
}

/**
 * Verifies that a campaign url is valid
 *
 * @param {String} campaignUrl Long URL of a campaign
 * @returns {Boolean} true if campaignUrl is valid
 */
function validateCampaignUrl(campaignUrl) {
  if (!campaignUrl) {
    return false;
  }

  const urlOptions = {
    protocols: ['https'],
    require_protocol: true,
    require_host: true,
    require_port: false,
    require_valid_protocol: true,
    allow_underscores: true,
    validate_length: true,
  };

  if (!isURL(campaignUrl, urlOptions)) {
    return false;
  }

  return true;
}

CampaignDetails.propTypes = {
  /**
   * banner text hook
   * @param {string} bannerText The string to set the banner text to
   */
  setBannerText: PropTypes.func.isRequired,
};

export default CampaignDetails;
