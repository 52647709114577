import {
  EuiModal,
  EuiFilePicker,
  EuiForm,
  EuiModalBody,
  EuiModalHeader,
  htmlIdGenerator,
  EuiButton,
  EuiModalFooter,
  EuiButtonEmpty,
  EuiFormRow,
  EuiFieldText,
  EuiProgress,
  EuiSpacer,
} from '@elastic/eui';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  cancelUpload,
  initSample,
  initUpload,
  sampleUploadComplete,
  uploadSample,
} from 'api/SampleApi';
import SampleTable from 'components/SampleTable/SampleTable';
import { SAMPLE_EXTENSIONS } from 'utils/constants';
import { createErrorToast, createSuccessToast } from 'utils/toasts';
import ToastContext from 'contexts/ToastContext';
import { usePrompt } from '../../hooks/usePrompt';
import csvParser from 'papaparse';
import { findUniqueIdColumn } from 'utils/sample';

/**
 * Modal to upload a sample
 */
function UploadSampleModal(props) {
  const [isUploading, setIsUploading] = useState(false);
  const [sampleName, setSampleName] = useState();
  const [contacts, setContacts] = useState();
  const [sampleTable, setSampleTable] = useState();
  const [uploadProgress, setUploadProgress] = useState();
  const sampleNameRef = useRef();

  useEffect(() => {
    sampleNameRef.value = sampleName;
  }, [sampleName]);

  const idGenerator = htmlIdGenerator('uploadSampleModal');
  const formId = idGenerator();
  const { id } = useParams();
  const { addToast } = useContext(ToastContext);
  const TOAST_TITLE = 'Upload Sample';

  const killUploads = () => {
    if (sampleNameRef.value) {
      addToast(createErrorToast(TOAST_TITLE, 'Upload failed.'));
      alert('Upload Failed');
      setIsUploading(false);
      setUploadProgress(0);
      cancelUpload(id, sampleNameRef.value, props.sampleType);
      sampleNameRef.value = '';
    }
  };

  const parseSample = async (sampleFile) => {
    let contactList = csvParser.parse(await sampleFile.text(), {
      delimiter: ',',
      header: true,
      transformHeader: (header) => header.trim().toLowerCase(),
      skipEmptyLines: true,
    });

    if (contactList?.errors?.length > 0) {
      addToast(
        createErrorToast(
          TOAST_TITLE,
          `Failed to read sample: ${contactList.errors[0]}`
        )
      );
      return [];
    }

    return contactList.data;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!contacts) {
      addToast(createErrorToast(TOAST_TITLE, 'Please upload a sample'));
      return;
    }

    if (!sampleName) {
      addToast(createErrorToast(TOAST_TITLE, 'Please enter a sample name'));
      return;
    }

    if (
      contacts.some(
        (contact) => isNaN(parseFloat(contact.tn)) || isNaN(contact.tn - 0)
      )
    ) {
      addToast(
        createErrorToast(TOAST_TITLE, 'Please ensure all tn are number')
      );
      return;
    }

    setIsUploading(true);

    // Package contacts to only useful data
    let firstnameKey = Object.keys(contacts[0]).find(
      (key) => key.toLowerCase() === 'firstname'
    );
    const uniqueIdKey = findUniqueIdColumn(contacts[0]);

    if (!uniqueIdKey) {
      addToast(createErrorToast(TOAST_TITLE, 'unique_id header was not found'));
      return;
    }

    let processedContacts = contacts.map((contact) => {
      return {
        projectId: id,
        token: contact.token,
        tn: contact.tn,
        uniqueId: contact[uniqueIdKey],
        firstname: contact[firstnameKey],
        email: contact.email,
      };
    });

    processedContacts = processedContacts.filter((contact) => {
      return contact.tn && contact.token && contact.projectId;
    });

    let chunks = [];
    const CHUNK_SIZE = 10000;

    while (processedContacts.length) {
      chunks.push(processedContacts.splice(0, CHUNK_SIZE));
    }

    let uploadedContacts = 0;
    let sampleId = null;
    try {
      sampleId = await initSample(id, sampleName, props.sampleType);
      if (!sampleId) {
        throw Error('Failed to create sample');
      }
    } catch (error) {
      addToast(createErrorToast(TOAST_TITLE, 'Failed to initialize sample.'));
      return;
    }

    await initUpload();

    for (const chunk of chunks) {
      try {
        let uploadResult = await uploadSample(id, sampleId, chunk);

        if (!uploadResult) {
          throw Error('Upload Failed');
        }
        uploadedContacts += chunk.length;
        setUploadProgress(
          Math.round((uploadedContacts / contacts.length) * 100)
        );
      } catch (error) {
        await killUploads();
        return;
      }
    }

    // Indicate to server that the upload completed
    let sampleCompletedResult = await sampleUploadComplete(id, sampleName);
    if (!sampleCompletedResult) {
      await killUploads();
      return;
    }

    // Prevent deletion on reload
    setSampleName();
    sampleNameRef.value = '';
    setIsUploading(false);
    addToast(createSuccessToast(TOAST_TITLE, 'Uploaded Sample'));
    props.onClose(true);
  };

  useEffect(() => {
    if (contacts) {
      setSampleTable(<SampleTable sample={contacts} />);
    }
  }, [contacts]);

  let navBlockMessage =
    'Navigating away will cancel all uploads.\n\nAre you sure you want to cancel your uploads?';
  const onClose = () => {
    // Cancel + Delete failed uploads on modal close
    if (!isUploading || window.confirm(navBlockMessage)) {
      killUploads();
      props.onClose(true);
    }
  };

  // Cancel + delete failed upload on navigation away from page. Refresh not included.
  usePrompt(navBlockMessage, isUploading, killUploads);

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>Upload a sample</EuiModalHeader>
      <EuiModalBody>
        <EuiForm id={formId} component='form' onSubmit={handleSubmit}>
          <EuiFormRow label='Sample name'>
            <EuiFieldText onChange={(e) => setSampleName(e.target.value)} />
          </EuiFormRow>
          <EuiFilePicker
            id={idGenerator()}
            accept={SAMPLE_EXTENSIONS}
            onChange={async (files) => {
              if (files.length > 0) {
                let parsedSample = await parseSample(files[0]);
                setContacts(parsedSample);
              }
            }}
          />
          {sampleTable}
          <EuiSpacer />
          {isUploading && (
            <EuiProgress
              value={uploadProgress}
              max={100}
              valueText={true}
              size='l'
            />
          )}
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiButton type='submit' form={formId} isLoading={isUploading}>
          Upload
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}

export default UploadSampleModal;
