import { useState } from 'react';
import {
  EuiButton,
  EuiFlexGrid,
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import SampleList from 'components/SampleList/SampleList';
import UploadSampleModal from 'components/UploadSampleModal/UploadSampleModal';

/**
 * Registered/Unregistered Sample manager component
 */
function CampaignSampleManager({
  title,
  samples,
  sampleType,
  setSamples,
  onUploadSample,
  loadingSamples,
}) {
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const showModal = () => setUploadModalVisible(true);
  const hideModal = async (hasUploaded) => {
    setUploadModalVisible(false);
    if (hasUploaded) {
      await onUploadSample();
    }
  };
  let totalCount = 0;
  let validCount = 0;

  for (const sample of samples) {
    totalCount += sample.uploadedContactCount;
    validCount += sample.uploadedContactCount - sample.dndContactCount;
  }

  let uploadModal;

  if (uploadModalVisible) {
    uploadModal = (
      <UploadSampleModal sampleType={sampleType} onClose={hideModal} />
    );
  }

  return (
    <EuiPanel hasBorder={true} hasShadow={false}>
      <EuiFlexGrid>
        <EuiFlexItem grow={9}>
          <EuiText textAlign='left'>
            <h2>{title}</h2>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton onClick={showModal}>Upload Sample</EuiButton>
        </EuiFlexItem>
      </EuiFlexGrid>
      <EuiFlexGrid>
        <EuiFlexItem grow={false}>
          <EuiPanel hasShadow={false}>
            <EuiSpacer size='l' />
            <div>
              <EuiIcon className='euiIcon--large' type='user' size='xxl' />
            </div>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem grow={3}>
          <EuiPanel hasShadow={false}>
            <EuiSpacer size='l' />
            <EuiText textAlign='center'>
              <h1>
                {validCount} / {totalCount}
              </h1>
              <p>Valid / Total Contacts</p>
            </EuiText>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem grow={9}>
          <SampleList
            samples={samples}
            setSamples={setSamples}
            loadingSamples={loadingSamples}
            sampleType={sampleType}
          />
        </EuiFlexItem>
      </EuiFlexGrid>
      {uploadModal}
    </EuiPanel>
  );
}

export default CampaignSampleManager;
