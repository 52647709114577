import { useReducer } from 'react';

/** A reducer function for form inputs. */
const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value,
  };
};

/**
 * Generic form hook for updating state
 * @param {Object} state The form object with input names as the key.
 */
function useFormData(state) {
  const [formData, setFormData] = useReducer(formReducer, state);

  return [formData, setFormData];
}

export default useFormData;
