import {
  htmlIdGenerator,
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiText,
  EuiSpacer,
} from '@elastic/eui';
import React, { useContext, useState } from 'react';
import { createBatch } from 'api/BatchApi';
import { useParams } from 'react-router-dom';
import SelectInvite from 'components/SelectInvite/SelectInvite';
import SelectTelecomProvider from 'components/SelectTelecomProvider/SelectTelecomProvider';
import CampaignContext from 'contexts/CampaignContext';
import UrlStatus from 'components/UrlStatus/UrlStatus';
import TestTextStatus from 'components/TestTextStatus/TestTextStatus';
import ToastContext from 'contexts/ToastContext';
import { createErrorToast, createSuccessToast } from 'utils/toasts';

/**
 * A modal to create batches
 */
function CreateBatchModal({ sample, sampleType, closeModal }) {
  const MAX_BATCH_SIZE = 30000;
  const [batchSize, setBatchSize] = useState(-1);
  const [isLoading, setIsLoading] = useState();
  const [invite, setInvite] = useState({ type: '', testcount: 0 });
  const [telecom, setTelecom] = useState(
    process.env.REACT_APP_DEFAULT_TELECOM_PROVIDER
  );
  const { validLongUrl } = useContext(CampaignContext);
  const { addToast } = useContext(ToastContext);

  const { id } = useParams();
  const idGenerator = htmlIdGenerator('batch-creation');
  const formId = idGenerator();
  const TOAST_TITLE = 'Create Batch';

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (batchSize < 1 || batchSize > MAX_BATCH_SIZE || !parseInt(batchSize)) {
      addToast(
        createErrorToast(
          `Must provide a batch size between 1 and ${MAX_BATCH_SIZE}`
        )
      );
      return;
    }

    if (!invite.id) {
      addToast(createErrorToast('Must select an invite message'));
      return;
    }

    setIsLoading(true);
    let result = await createBatch(
      id,
      sample._id,
      batchSize,
      invite.id,
      telecom
    );
    setIsLoading(false);

    if (!result) {
      addToast(createErrorToast('Failed to create batch'));
    } else {
      addToast(createSuccessToast(TOAST_TITLE, 'Batch creation request sent'));
      window.location.reload(); // Get latest sample batch values
    }
  };

  return (
    <EuiModal onClose={closeModal}>
      <EuiModalHeader>{sample.name} - Batch Creation</EuiModalHeader>
      <EuiModalBody>
        <EuiForm component='form' id={formId} onSubmit={handleSubmit}>
          <EuiFormRow label='Sample Type'>
            <EuiText>
              {sampleType[0].toUpperCase() + sampleType.slice(1)}
            </EuiText>
          </EuiFormRow>
          <EuiFormRow label='Remaining Contacts'>
            <EuiText>{sample.remainingContactCount}</EuiText>
          </EuiFormRow>
          <EuiFormRow label='Batch Size'>
            <EuiFieldText onChange={(e) => setBatchSize(e.target.value)} />
          </EuiFormRow>
          <EuiFormRow label='Invite Message'>
            <SelectInvite
              campaignId={id}
              onChange={(invite) => {
                setInvite(invite);
              }}
              inviteType={sampleType}
            />
          </EuiFormRow>
          <EuiFormRow label='Invite Type'>
            <EuiText>{invite?.type || 'None'}</EuiText>
          </EuiFormRow>
          <EuiFormRow label='Texting Provider'>
            <SelectTelecomProvider
              onChange={(telecom) => setTelecom(telecom)}
            />
          </EuiFormRow>
          <EuiSpacer />
          <hr />
          <EuiSpacer />

          <EuiFormRow>
            <UrlStatus />
          </EuiFormRow>
          <EuiFormRow>
            <TestTextStatus status={invite.testcount > 0} />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>
        <EuiButton
          type='submit'
          form={formId}
          isLoading={isLoading}
          isDisabled={!(validLongUrl && invite.testcount)}
        >
          Create Batch
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}

export default CreateBatchModal;
