import { useContext, useEffect, useState } from 'react';
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  htmlIdGenerator,
} from '@elastic/eui';
import useFormData from 'hooks/useFormData';
import { getTestTextPreview, sendTestText } from 'api/SendTestTextApi';
import SelectInvite from 'components/SelectInvite/SelectInvite';
import SelectTelecomProvider from 'components/SelectTelecomProvider/SelectTelecomProvider';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import CampaignContext from 'contexts/CampaignContext';
import UrlStatus from 'components/UrlStatus/UrlStatus';
import TestTextMessagePreview from 'components/TestTextMessagePreview/TestTextMessagePreview';
import TestTextMmsPreview from 'components/TestTextMmsPreview/TestTextMmsPreview';
import { createErrorToast } from 'utils/toasts';
import ToastContext from 'contexts/ToastContext';
import { validateMessage } from 'utils/invites';

/** A modal with an embedded form to create users */
function SendTestTextModal({ closeModal, sampleId, sampleType }) {
  const [previewData, setPreviewData] = useState({
    message: '',
    shortUrl: '',
    mmsUrl: '',
  });
  const [formData, setFormData] = useFormData({
    phoneNumber: '',
    messageId: '',
    telecom: process.env.REACT_APP_DEFAULT_TELECOM_PROVIDER,
  });
  const { validLongUrl, campaign, setCampaign } = useContext(CampaignContext);
  const [isLoading, setIsLoading] = useState(false);
  const formId = htmlIdGenerator('test_text')();
  const { id } = useParams();
  const { addToast } = useContext(ToastContext);
  const TOAST_TITLE = 'Send Test Text';

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.phoneNumber) {
      addToast(
        createErrorToast(
          TOAST_TITLE,
          'Please enter a test phone number to continue.'
        )
      );
      return;
    }
    if (!formData.messageId) {
      addToast(
        createErrorToast(TOAST_TITLE, 'Please select a message to continue.')
      );
      return;
    }

    const errorMessage = validateMessage(
      previewData.message,
      sampleType === 'registered'
    );
    if (errorMessage) {
      addToast(createErrorToast(TOAST_TITLE, errorMessage));
      return;
    }

    setIsLoading(true);

    const response = await sendTestText(
      id,
      formData.phoneNumber,
      formData.messageId,
      sampleId,
      formData.telecom
    );
    if (response) {
      setCampaign({
        ...campaign,
        testCount: campaign.testCount + 1,
      });
      closeModal();
    } else {
      addToast(
        createErrorToast(
          TOAST_TITLE,
          'An error has occured, the test text was not sent.'
        )
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const loadTestPreview = async () => {
      let previewResponse = await getTestTextPreview(
        id,
        formData.messageId,
        sampleId,
        sampleType
      );
      setPreviewData(previewResponse);
    };

    if (formData.messageId) {
      loadTestPreview();
    }
  }, [formData]);

  return (
    <EuiModal
      onClose={closeModal}
      maxWidth={700}
      initialFocus='[name="phoneNumber"]'
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Send Test Text Message</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFlexGroup gutterSize='m'>
          {/* Left Column */}
          <EuiFlexItem grow={4}>
            <EuiForm component='form' id={formId} onSubmit={handleSubmit}>
              <EuiFormRow label='Phone Number'>
                <EuiFieldText
                  name='phoneNumber'
                  placeholder='1234567890'
                  onChange={setFormData}
                />
              </EuiFormRow>
              <EuiFormRow label='Text Message'>
                <SelectInvite
                  name='messageId'
                  onChange={(invite) =>
                    setFormData({
                      target: { name: 'messageId', value: invite.value },
                    })
                  }
                  campaignId={id}
                  inviteType={sampleType}
                />
              </EuiFormRow>
              <EuiFormRow label='Texting Provider'>
                <SelectTelecomProvider
                  onChange={(telecom) =>
                    setFormData({ target: { name: 'telecom', value: telecom } })
                  }
                />
              </EuiFormRow>
            </EuiForm>
            <EuiSpacer />
            <EuiFormRow>
              <UrlStatus />
            </EuiFormRow>
          </EuiFlexItem>
          {/* Right column */}
          <EuiFlexItem grow={6}>
            <EuiFormRow
              label={`Message Preview (${previewData.message.length} characters)`}
            >
              <TestTextMessagePreview message={previewData.message} />
            </EuiFormRow>
            <EuiFormRow alt='mms preview' label='MMS Preview'>
              <TestTextMmsPreview url={previewData.mmsUrl} />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>
        <EuiButton
          type='submit'
          form={formId}
          fill
          isLoading={isLoading}
          disabled={!validLongUrl}
        >
          Send Test Text
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}

SendTestTextModal.propTypes = {
  /**
   * Function for closing the modal
   */
  closeModal: PropTypes.func.isRequired,

  /**
   * ID of the sample to test
   */
  sampleId: PropTypes.string.isRequired,
};

export { SendTestTextModal };
