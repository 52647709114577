const { default: AppApi } = require('services/appApi');

/**
 * Download all conversations.
 * @param {Date} date - Retrieve conversations on this date
 */
async function downloadConversations(date) {
  try {
    let res = await AppApi.get('/downloadConversations', {
      params: {
        date: date,
      },
    });

    if (res.status !== 200) {
      throw res;
    }
    return res.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export { downloadConversations };
