import './App.css';
import { Outlet, BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import { EuiGlobalToastList, EuiText } from '@elastic/eui';
import UserContext from 'contexts/UserContext';
import ToastContext from 'contexts/ToastContext';
import useTokens from 'hooks/useTokens';
import LoginScreen from 'pages/LoginScreen/LoginScreen';
import CampaignDashboard from 'pages/CampaignDashboard/CampaignDashboard';
import CampaignDetails from 'pages/CampaignDetails/CampaignDetails';
import Banner from 'components/widgets/Banner';
import Contacts from 'pages/Contacts/Contacts';
import Tools from 'pages/Tools/Tools';
import AdminScreen from 'pages/AdminDashboard/AdminDashboard';
import PageWithSidebar from 'templates/PageWithSidebar/PageWithSidebar';
import RegisteredCampaignsProvider from 'contexts/RegisteredCampaignsProvider';

/** Main application */
function App() {
  let initRole = sessionStorage.getItem('ur');
  const [role, setRole] = useState(initRole);
  const [username, setUsername] = useState();
  const [bannerText, setBannerText] = useState();
  const [toasts, setToasts] = useState([]);

  const toastContextValue = {
    addToast: (toastToAdd) => setToasts([...toasts, toastToAdd]),
  };

  const { accessToken, refreshToken, setTokens } = useTokens();

  const updateRole = (newRole) => {
    setRole(newRole);
    sessionStorage.setItem('ur', newRole);
  };

  let toastList = (
    <EuiGlobalToastList
      toasts={toasts}
      dismissToast={(toRemove) => {
        setToasts(toasts.filter((toast) => toast.id !== toRemove.id));
      }}
      toastLifeTimeMs={3000}
    />
  );

  if (!(accessToken || refreshToken)) {
    return (
      <ToastContext.Provider value={toastContextValue}>
        <LoginScreen
          setTokens={setTokens}
          setRole={updateRole}
          setUsername={setUsername}
        />
        {toastList}
      </ToastContext.Provider>
    );
  } else {
    return (
      <div className='App'>
        <BrowserRouter basename='/app'>
          <ToastContext.Provider value={toastContextValue}>
            <UserContext.Provider value={{ role, username }}>
              <RegisteredCampaignsProvider>
                <Banner setTokens={setTokens} bannerText={bannerText} />
                <Routes>
                  <Route
                    index
                    element={
                      <CampaignDashboard setBannerText={setBannerText} />
                    }
                  />
                  <Route
                    path='/dashboard'
                    element={
                      <CampaignDashboard setBannerText={setBannerText} />
                    }
                  />
                  <Route
                    path='/campaign/:id'
                    element={<CampaignDetails setBannerText={setBannerText} />}
                  />
                  <Route
                    path='/campaign/:id/contacts'
                    element={<Contacts setBannerText={setBannerText} />}
                  />
                  <Route
                    path='/admin'
                    element={<AdminScreen setBannerText={setBannerText} />}
                  />
                  <Route
                    path='/tools'
                    element={<Tools setBannerText={setBannerText} />}
                  />
                  <Route
                    path='*'
                    element={
                      <div className='pageNotFound'>
                        <EuiText>
                          <PageWithSidebar
                            bannerText='This page does not exist'
                            setBannerText={setBannerText}
                          />
                        </EuiText>
                      </div>
                    }
                  />
                </Routes>
                {toastList}
              </RegisteredCampaignsProvider>
            </UserContext.Provider>
          </ToastContext.Provider>
        </BrowserRouter>
        <Outlet />
      </div>
    );
  }
}

export default App;
