import React, { useContext } from 'react';
import {
  EuiPanel,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
} from '@elastic/eui';
import CampaignContext from 'contexts/CampaignContext';
import { createErrorToast, createSuccessToast } from 'utils/toasts';
import ToastContext from 'contexts/ToastContext';

/**
 * Campaign longUrl component
 */
function CampaignUrl() {
  const { campaign } = useContext(CampaignContext);
  const { addToast } = useContext(ToastContext);

  /**
   * Copies a url to the keyboard
   * @param {String} surveyLongUrl - URL to copy
   */
  async function copyPageUrl(surveyLongUrl) {
    try {
      await navigator.clipboard.writeText(surveyLongUrl);
      addToast(
        createSuccessToast('URL Copy', 'Survey URL copied to clipboard')
      );
    } catch (err) {
      addToast(createErrorToast('URL Copy', 'Failed to copy survey url'));
    }
  }

  return (
    <EuiPanel hasBorder={true} hasShadow={false}>
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem grow={1}>
          <EuiText size='m' textAlign='left'>
            <h2>Survey URL</h2>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={4}>
          <EuiText size='m' textAlign='left'>
            {campaign.surveyLongUrl}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton
            onClick={async () => {
              await copyPageUrl(campaign.surveyLongUrl);
            }}
          >
            Copy
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton
            onClick={() => {
              window.open(campaign.surveyLongUrl, '_blank');
            }}
          >
            View
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
}

export default CampaignUrl;
