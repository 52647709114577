import {
  EuiButton,
  EuiDatePicker,
  EuiDatePickerRange,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { useState } from 'react';
import CarrierChart from 'components/CarrierChart/CarrierChart';
import { getCarrierData } from 'api/servicesApi';
import moment from 'moment';

/**
 * Component to view the statistics for the sent texts' carriers.
 */
function CarrierInfo() {
  let [startDate, setStartDate] = useState(moment());
  let [endDate, setEndDate] = useState(moment());
  let [graphData, setGraphData] = useState([]);
  let [tableData, setTableData] = useState([]);

  const filterCarrierData = () => {
    const retrieveCarrierData = async () => {
      let carrierDataByDate = await getCarrierData(startDate, endDate);
      let sumCounts = {};
      let series = {};

      // Create timeseries data for graph
      for (let [date, dailyCarrierData] of Object.entries(carrierDataByDate)) {
        date = new Date(parseInt(date));
        for (const [carrier, count] of Object.entries(dailyCarrierData)) {
          if (!Object.keys(series).includes(carrier)) {
            series[carrier] = [];
            sumCounts[carrier] = 0;
          }

          series[carrier].push({
            x: new Date(date).getTime(),
            y: count,
          });

          sumCounts[carrier] += count;
        }
      }

      series = Object.entries(series).map(([carrier, value]) => {
        return {
          name: carrier,
          data: value,
        };
      });

      setGraphData(series);
      setTableData(
        Object.entries(sumCounts).map(([carrier, count]) => {
          return {
            carrier,
            count,
          };
        })
      );
    };
    retrieveCarrierData();
  };

  return (
    <>
      <EuiPanel hasBorder={false} hasShadow={false} paddingSize='l'>
        <EuiText>
          <h1>Texts sent by carrier</h1>
        </EuiText>
        <EuiSpacer />
        <EuiFlexGroup justifyContent='spaceAround'>
          <EuiFlexItem grow={false}>
            <EuiText textAlign='left'>Select a date range</EuiText>
            <EuiDatePickerRange
              startDateControl={
                <EuiDatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  startDate={startDate}
                  endDate={endDate}
                  isInvalid={startDate > endDate}
                  showTimeSelect={false}
                  maxDate={endDate}
                  utcOffset={0}
                />
              }
              endDateControl={
                <EuiDatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  startDate={startDate}
                  endDate={endDate}
                  showTimeSelect={false}
                  minDate={startDate}
                  utcOffset={0}
                />
              }
            />
            <EuiButton onClick={() => filterCarrierData()}>Search</EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer />

        <CarrierChart
          title='Carrier breakdown'
          height={500}
          series={graphData}
          labels={['Text Counts']}
        />
        <EuiInMemoryTable
          items={tableData}
          columns={[
            { name: 'Carrier', field: 'carrier' },
            { name: 'Total Texts Sent Between Dates', field: 'count' },
          ]}
        />
      </EuiPanel>
    </>
  );
}

export default CarrierInfo;
