/**
 * Find the unique_id column in a sample (case-insensitive).
 *
 * @param {Object} sampleRecord - Record from a sample
 * @returns {String} Column name from the sample
 */
export function findUniqueIdColumn(sampleRecord) {
  if (!sampleRecord) {
    return '';
  }

  let uniqueIdColumn = '';

  for (const columnName of Object.keys(sampleRecord)) {
    const normalizedColumnName = columnName.toLowerCase();
    if (normalizedColumnName == 'unique_idf') {
      uniqueIdColumn = columnName;
      break;
    } else if (normalizedColumnName == 'unique_id') {
      uniqueIdColumn = columnName;
    }
  }

  return uniqueIdColumn;
}
