import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSelect,
} from '@elastic/eui';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { zoneToCode } from 'utils/zone';
import { RegisteredCampaignsContext } from '../../contexts/RegisteredCampaignsProvider';

/**
 * Select a registered campaign
 */
function RegisteredCampaignSelect({ name, state, onChange, value, disabled }) {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const stateCode = zoneToCode(state);
  const { registeredCampaigns, updateRegisteredCampaigns } = useContext(
    RegisteredCampaignsContext
  );

  useEffect(() => {
    let newOptions = [];

    if (Object.keys(registeredCampaigns).includes(stateCode)) {
      newOptions = registeredCampaigns[stateCode].map((campaign) => {
        return {
          value: campaign.campaignId,
          text: campaign.name,
        };
      });
    }

    newOptions.unshift({
      value: '',
      text: '-- Please select a campaign --',
    });

    setOptions(newOptions);
  }, [registeredCampaigns, state]);

  const refresh = () => {
    const sync = async () => {
      setIsLoading(true);
      await updateRegisteredCampaigns();
      setIsLoading(false);
    };

    sync();
  };

  return (
    <>
      <EuiFormRow label='Registered campaigns'>
        <EuiFlexGroup>
          <EuiFlexItem grow={true}>
            <EuiSelect
              name={name}
              onChange={onChange}
              value={value}
              options={options}
              isLoading={isLoading}
              disabled={disabled}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              iconType='refresh'
              size='s'
              onClick={refresh}
              isLoading={isLoading}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
    </>
  );
}

RegisteredCampaignSelect.propTypes = {
  /**
   * The name of the select element
   */
  name: PropTypes.string,

  /**
   * The state update function for the containing form
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The value of the input
   */
  value: PropTypes.string.isRequired,
};

export default RegisteredCampaignSelect;
