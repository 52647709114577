import AppApi from 'services/appApi';

let uploadController = new AbortController();

/**
 * Create a new sample with no contacts.
 *
 * @param {String} campaignId - ID of campaign to upload a sample to
 * @param {String} sampleName - Name to give the sample
 * @param {String} sampleType - type of sample (registered/unregistered)
 * @returns {String} id of the created sample. null if failed to create sample
 */
async function initSample(campaignId, sampleName, sampleType) {
  try {
    let result = await AppApi.post('/createSample', {
      campaignId: campaignId,
      sampleName: sampleName,
      sampleType: sampleType,
    });

    return result.data.sampleId;
  } catch (error) {
    console.error(error);
  }

  return '';
}

/**
 * Upload contacts from a sample file.
 *
 * @param {String} campaignId - ID of campaign to upload a sample to
 * @param {String} sampleId - ID to of the sample to add contacts to
 * @param {Array<Object>} contacts - Processed sample object ready to be sent to server
 * @returns {Boolean} - Uploaded sample's upload status
 */
async function uploadSample(campaignId, sampleId, contacts) {
  try {
    if (!uploadController.signal.aborted) {
      await AppApi.post(
        '/uploadContacts',
        {
          campaignId: campaignId,
          sampleId: sampleId,
          contacts: contacts,
        },
        {
          signal: uploadController.signal,
        }
      );
      return true;
    }

    throw Error('Upload was aborted by user');
  } catch (err) {
    console.error(err);
    return false;
  }
}

/**
 * Indicate that a sample has been fully uploaded.
 *
 * @param {String} campaignId - ID of a campaign
 * @param {String} sampleName - Name of a sample
 * @returns {Boolean} - true on success
 */
async function sampleUploadComplete(campaignId, sampleName) {
  try {
    await AppApi.post('/completedSampleUpload', {
      success: true,
      projectId: campaignId,
      sampleName: sampleName,
    });

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}
/**
 * Get all samples for a campaign.
 *
 * @param {String} campaignId ID of a campaign
 * @returns {Array<Object>} A list of the campaigns samples
 */
async function getSamples(campaignId) {
  try {
    let result = await AppApi.get(`/getSamples/${campaignId}`);
    if (result.status !== 200) {
      throw result;
    }
    return result.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

/**
 * Get all contacts of a sample.
 *
 * @param {String} sampleId ID of a sample
 * @returns {File} A csv file of contacts from the given sample
 */
async function downloadSampleContacts(sampleId) {
  try {
    let res = await AppApi.post('/downloadSampleContacts', {
      sampleId: sampleId,
    });
    if (res.status !== 200) {
      throw res;
    }
    return res.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

/**
 * Delete specified sample
 *
 * @param {String} sampleId - ID of a sample to delete
 * @param {String} sampleType - type of sample (registered/unregistered)
 * @returns {Boolean} true on success
 */
async function deleteSample(sampleId, sampleType) {
  try {
    await AppApi.delete('/deleteSample', {
      data: {
        sampleId,
        sampleType,
      },
    });
    return true;
  } catch (err) {
    return false;
  }
}

/**
 * Delete a failed uploaded sample
 *
 * @param {String} projectId - ID the sample belongs to
 * @param {String} sampleName - Name of the failed sample
 * @param {String} sampleType - type of sample (registered/unregistered)
 * @returns {Boolean} true on success
 */
async function deleteFailedUpload(projectId, sampleName, sampleType) {
  try {
    await AppApi.delete('/deleteFailedSampleUpload', {
      data: {
        projectId,
        sampleName,
        sampleType,
      },
    });
    return true;
  } catch (err) {
    return false;
  }
}

/**
 * Abort upload
 *
 * @param {String} projectId - ID the sample belongs to
 * @param {String} sampleName - Name of the failed sample
 * @param {String} sampleType - type of sample (registered/unregistered)
 */
function cancelUpload(projectId, sampleName, sampleType) {
  try {
    uploadController.abort('User cancelled upload');
  } catch (err) {
    console.log(err);
  }

  deleteFailedUpload(projectId, sampleName, sampleType);
}

/**
 * Initialize a new controller to manage uploading
 */
function initUpload() {
  uploadController = new AbortController();
}

export {
  initSample,
  uploadSample,
  sampleUploadComplete,
  getSamples,
  downloadSampleContacts,
  deleteSample,
  cancelUpload,
  initUpload,
};
