import { getCampaignMessages } from 'api/CampaignMessagesApi';
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import CampaignMessages from './CampaignMessages';
import CampaignContext from 'contexts/CampaignContext';

/**
 * A campaigns message component
 */
function CampaignMessageManager() {
  const [isLoading, setIsLoading] = useState(true);
  const [registeredInvites, setRegisteredInvites] = useState([]);
  const [unregisteredInvites, setUnregisteredInvites] = useState([]);
  const { id } = useParams();
  const { campaign } = useContext(CampaignContext);

  useEffect(() => {
    const loadInvites = async () => {
      setIsLoading(true);
      const invites = await getCampaignMessages(id);
      setUnregisteredInvites(invites.unregistered);
      setRegisteredInvites(invites.registered);
      setIsLoading(false);
    };

    loadInvites();
  }, []);

  return (
    <>
      {isLoading ? (
        <h2>Loading invites</h2>
      ) : (
        <>
          {campaign.isRegistered && (
            <CampaignMessages
              title='Registered Messages'
              invites={registeredInvites}
              setInvites={setRegisteredInvites}
              isRegisteredMessage={true}
            />
          )}
          <CampaignMessages
            title='Unregistered Messages'
            invites={unregisteredInvites}
            setInvites={setUnregisteredInvites}
            isRegisteredMessage={false}
          />
        </>
      )}
    </>
  );
}

export default CampaignMessageManager;
