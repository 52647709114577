import { useContext, useEffect, useState } from 'react';
import {
  EuiButton,
  EuiButtonEmpty,
  EuiCheckbox,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  htmlIdGenerator,
} from '@elastic/eui';
import { createCampaign, editCampaign } from 'api/CampaignApi';
import StateSelect from 'components/widgets/StateSelect';
import useFormData from 'hooks/useFormData';
import PropTypes from 'prop-types';
import { createErrorToast, createSuccessToast } from 'utils/toasts';
import ToastContext from 'contexts/ToastContext';
import CampaignContext from 'contexts/CampaignContext';
import RegisteredCampaignSelect from 'components/widgets/RegisteredCampaignSelect';
import { getUpdatedRegisteredTnCount } from 'api/RegisteredCampaign';
import CampaignMacroList from 'components/CampaignMacroList/CampaignMacroList';
import { hasAccessToConfidentialProjects } from 'utils/privledges';
import UserContext from 'contexts/UserContext';

/** A modal with an embedded form to initialize or edit campaigns */
function CampaignDetailsModal({
  closeModal, // modal state hook
  initFormData = {
    name: '',
    surveyLongUrl: '',
    zone: '', // state
    registeredCampaignId: '', //selected registered campaign id
  },
  campaignId = undefined,
}) {
  const { campaign } = useContext(CampaignContext);
  const { role } = useContext(UserContext);
  const [formData, setFormData] = useFormData(initFormData);
  const [registeredTnCount, setRegisteredTnCount] = useState(0);
  const [isRegisteredCampaign, setIsRegisteredCampaign] = useState(
    campaign.isRegistered
  );
  const [isConfidential, setIsConfidential] = useState(campaign.isConfidential);
  const [isLoading, setIsLoading] = useState(false);
  const [campaignMacros, setCampaignMacros] = useState(campaign?.macros ?? []);
  const { addToast } = useContext(ToastContext);

  const submitRequest = campaignId ? editCampaign : createCampaign;
  const formId = htmlIdGenerator();
  let modalHeaderTitle, btnSubmitText, alertText;

  if (campaignId) {
    // Set text for modal
    modalHeaderTitle = 'Edit Campaign Details';
    btnSubmitText = 'Update Campaign';
    alertText = 'Something went wrong. The campaign was not updated.';
  } else {
    modalHeaderTitle = 'Create New Campaign';
    btnSubmitText = 'Create Campaign';
    alertText = 'Something went wrong. The campaign was not created.';
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let requestFormData = {
      ...formData,
      isRegistered: isRegisteredCampaign,
      isConfidential: isConfidential,
      macros: campaignMacros,
    };

    if (!requestFormData.name) {
      addToast(
        createErrorToast(modalHeaderTitle, 'Must enter a campaign name')
      );
      return;
    }
    if (!requestFormData.zone) {
      addToast(createErrorToast(modalHeaderTitle, 'Must select a state'));
      return;
    }

    if (campaignId) {
      requestFormData = {
        updatedData: {
          ...requestFormData,
        },
        projectId: campaignId,
      };
    }
    setIsLoading(true);
    const response = await submitRequest(requestFormData);
    setIsLoading(false);

    if (response) {
      closeModal();
      addToast(createSuccessToast(modalHeaderTitle, 'Success'));
      window.location.href = `${window.location.origin}/app/campaign/${response.projectId}`;
    } else {
      addToast(createErrorToast(alertText));
    }
  };

  const updateRegisteredTnCount = () => {
    const update = async () => {
      if (formData.registeredCampaignId) {
        const count = await getUpdatedRegisteredTnCount(
          formData.registeredCampaignId
        );
        setRegisteredTnCount(count);
      } else {
        setRegisteredTnCount(0);
      }
    };
    update();
  };

  useEffect(() => {
    // Reset selected registered campaign
    formData.registeredCampaignId = '';
  }, [formData.zone]);

  useEffect(() => {
    updateRegisteredTnCount();
  }, [formData.registeredCampaignId, formData.zone, isRegisteredCampaign]);

  return (
    <EuiModal onClose={closeModal} initialFocus='[name="name"]'>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>{modalHeaderTitle}</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm
          id={formId('create_campaign')}
          component='form'
          onSubmit={handleSubmit}
        >
          <EuiFormRow label='Campaign Name'>
            <EuiFieldText
              name='name'
              placeholder='Campaign Name'
              defaultValue={formData.name}
              onChange={setFormData}
            />
          </EuiFormRow>
          <EuiFormRow label='Survey URL'>
            <EuiFieldText
              name='surveyLongUrl'
              placeholder='http://example.com'
              defaultValue={formData.surveyLongUrl}
              onChange={setFormData}
            />
          </EuiFormRow>
          <EuiCheckbox
            id='registeredCheckbox'
            name='isRegistered'
            label='Registered'
            checked={isRegisteredCampaign}
            onChange={(e) => setIsRegisteredCampaign(e.target.checked)}
          />
          <StateSelect
            name='zone'
            value={formData.zone}
            onChange={setFormData}
            disabled={campaignId && isRegisteredCampaign}
          />
          {isRegisteredCampaign && (
            <>
              <RegisteredCampaignSelect
                name='registeredCampaignId'
                value={formData.registeredCampaignId}
                onChange={setFormData}
                state={formData.zone}
              />
              <EuiText>Sending TNs: {registeredTnCount}</EuiText>
            </>
          )}
          {
            hasAccessToConfidentialProjects(role) && 
            <>
              <EuiCheckbox
                id='confidentialCheckbox'
                name='confidentialCheckbox'
                label='Confidential Campaign'
                checked={isConfidential}
                onChange={(e) => setIsConfidential(e.target.checked)}
                
              />
              <CampaignMacroList 
                initialMacros={campaignMacros}
                onChange={(updatedMacro) => setCampaignMacros(macros => {
                  macros[updatedMacro.index] = updatedMacro.macro
                  return macros;
                })
                }
              />
            </>
          }
          
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>
        <EuiButton
          type='submit'
          form={formId('create_campaign')}
          fill
          isLoading={isLoading}
        >
          {btnSubmitText}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}

CampaignDetailsModal.propTypes = {
  /**
   * Modal close function
   */
  closeModal: PropTypes.func.isRequired,

  /**
   * Prefilled form data
   */
  initFormData: PropTypes.shape({
    /**
     * Name of campaign
     */
    name: PropTypes.string,

    /**
     * The surveys full URL
     */
    surveyLongUrl: PropTypes.string,

    /**
     * Name of zone (state)
     */
    zone: PropTypes.string,
  }),

  /**
   * A campaign ID to edit
   */
  campaignId: PropTypes.string,
};

export default CampaignDetailsModal;
