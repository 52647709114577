import AppApi from 'services/appApi';

/**
 * Download a contact history based on given name or phone number.
 *
 * @param {String} [firstName=null] - The string of the first name.
 * @param {String} [lastName=null] - The string of the last name.
 * @param {String} [phone=null] - The string of the given phone number.
 * @returns {String} Success message or null.
 */
async function downloadContactHistory(
  firstName = null,
  lastName = null,
  phone = null
) {
  try {
    const res = await AppApi.get('/downloadContactHistory', {
      params: {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
      },
    });

    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export { downloadContactHistory };
