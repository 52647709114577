const { USER_ROLES } = require('./constants');

/**
 * Check if a role has access to confidential projects.
 *
 * @param {String} role - User role
 * @returns {Boolean} true if the role has access
 */
function hasAccessToConfidentialProjects(role) {
  return [USER_ROLES.ADMIN, USER_ROLES.CA].includes(role);
}

module.exports = {
  hasAccessToConfidentialProjects,
};
