import React, { useContext } from 'react';
import { EuiFlexGrid, EuiFlexItem, EuiPanel, EuiText } from '@elastic/eui';
import CampaignPieChart from 'components/CampaignPieChart/CampaignPieChart';
import CampaignContext from 'contexts/CampaignContext';

/**
 * Campaign stats component
 */
function CampaignStats() {
  const { campaignStatsData } = useContext(CampaignContext);
  let stats = computeStats(campaignStatsData);

  return (
    <EuiFlexGrid gutterSize='none'>
      <EuiFlexItem>
        <CampaignPieChart
          title='Clicks'
          series={stats.clicksData}
          labels={['Not click', 'Clicks']}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <CampaignPieChart
          title='Opt-Outs'
          series={stats.optOutsData}
          labels={['Stay', 'Opt-Outs']}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={2}>
        <EuiPanel hasShadow={false}>
          <EuiText textAlign='center'>
            <h3>Text Replied</h3>
            <h3>{stats.repliedCount}</h3>
          </EuiText>
        </EuiPanel>
      </EuiFlexItem>
      <EuiFlexItem>
        <CampaignPieChart
          title='Delivery Rate'
          series={stats.deliveryRate}
          labels={['Not Delivered', 'Delivered']}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <CampaignPieChart
          title='Spam Rate'
          series={stats.spamRate}
          labels={['Not Spam', 'Spam']}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={2}>
        <EuiPanel hasShadow={false}>
          <EuiText textAlign='center'>
            <h3>Tests Sent</h3>
            <h3>{stats.testCount}</h3>
          </EuiText>
        </EuiPanel>
      </EuiFlexItem>
    </EuiFlexGrid>
  );
}

/**
 * Calculate various campaign stats.
 *
 * @param {Object} campaignStatsData Campaign data to compute campaign stats
 * @returns {Object} Keyed index of all computed stats
 */
function computeStats(campaignStatsData) {
  let clicksData = [];
  let optOutsData = [];
  let deliveryRate = [];
  let spamRate = [];
  let totalSentCount = 'N/A';
  let repliedCount = campaignStatsData.repliedCount ?? 'N/A';
  let testCount = campaignStatsData.testCount ?? 'N/A';

  if (campaignStatsData.totalSentCount) {
    totalSentCount = campaignStatsData.totalSentCount;

    clicksData = [
      Math.max(campaignStatsData.totalSentCount - campaignStatsData.clicks, 0),
      campaignStatsData.clicks,
    ];

    if (campaignStatsData.totalSentCount >= campaignStatsData.dncCount) {
      optOutsData = [
        campaignStatsData.totalSentCount - campaignStatsData.dncCount,
        campaignStatsData.dncCount,
      ];
    }

    if (campaignStatsData.totalSentCount >= campaignStatsData.deliveredCount) {
      deliveryRate = [
        campaignStatsData.totalSentCount - campaignStatsData.deliveredCount,
        campaignStatsData.deliveredCount,
      ];
    }

    if (campaignStatsData.totalSentCount >= campaignStatsData.spamReports) {
      spamRate = [
        campaignStatsData.totalSentCount - campaignStatsData.spamReports,
        campaignStatsData.spamReports,
      ];
    }
  }

  return {
    clicksData,
    optOutsData,
    deliveryRate,
    spamRate,
    totalSentCount,
    repliedCount,
    testCount,
  };
}

export default CampaignStats;
