import React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';

/**
 * Campaign Pie chart stats
 */
function CampaignPieChart({
  title,
  series,
  labels,
  height = 250,
  width = 250,
}) {
  let options = {
    colors: ['#E02401', '#F78812', '#AB6D23', '#51050F'],
    title: {
      text: title,
      align: 'center',
      style: {
        fontSize: '22px',
        fontWeight: 'bold',
      },
    },
    legend: {
      show: false,
    },
    labels: labels,
    dataLabels: {
      enabled: false,
    },
    noData: {
      text: 'N/A',
      style: {
        fontSize: '22px',
        fontWeight: 'bold',
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '65%',
          background: 'transparent',
          labels: {
            show: true,
            name: {
              show: true,
              floating: true,
              offsetY: 30,
            },
            value: {
              show: true,
              floating: true,
              fontSize: '22px',
              fontWeight: 'bold',
              offsetY: -15,
            },
            total: {
              label: '',
              show: true,
              fontSize: '22px',
              fontWeight: 'bold',
              color: '#373d3f',
              formatter: (w) => {
                let sum = w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);

                let percent;
                if (sum === 0) {
                  percent = 0;
                } else {
                  percent = (w.globals.seriesTotals[1] / sum) * 100;
                }
                return percent.toFixed(1) + '%';
              },
            },
          },
        },
      },
    },
  };

  return (
    <Chart
      type='donut'
      height={height}
      width={width}
      series={series}
      options={options}
    />
  );
}

CampaignPieChart.propTypes = {
  /**
   * Title to display on the chart
   */
  title: PropTypes.string,

  /**
   * Data to use in the chart
   */
  series: PropTypes.arrayOf(PropTypes.number).isRequired,

  /**
   * Labels for the data, should be the same length as series
   */
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * Height of chart
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * Width of chart
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CampaignPieChart;
