import { useContext, useEffect, useState } from 'react';
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldPassword,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSelect,
  htmlIdGenerator,
} from '@elastic/eui';
import { createUser, deleteUser, getAllUsers } from 'api/AdminApi';
import useFormData from 'hooks/useFormData';
import ToastContext from 'contexts/ToastContext';
import { createSuccessToast, createErrorToast } from 'utils/toasts';

/** A modal with an embedded form to create users */
function CreateUserModal({
  closeModal, // modal state hook
}) {
  const [formData, setFormData] = useFormData({
    username: '',
    email: '',
    password: '',
    role: 'user',
  });
  const [isLoading, setIsLoading] = useState(false);
  const formId = htmlIdGenerator('create_usr')();
  const { addToast } = useContext(ToastContext);
  const TOAST_TITLE = 'Account Creation';
  const ROLE_OPTIONS = [
    { text: 'CA', value: 'user' },
    { text: 'COL', value: 'col-user' },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.username) {
      addToast(createErrorToast(TOAST_TITLE, 'Must provide a username'));
      return;
    }
    if (!formData.email) {
      addToast(createErrorToast(TOAST_TITLE, 'Must provide an email'));
      return;
    }
    if (!formData.password) {
      addToast(createErrorToast(TOAST_TITLE, 'Must provide a password'));
      return;
    }
    setIsLoading(true);
    const response = await createUser(formData);
    if (response) {
      addToast(createSuccessToast(TOAST_TITLE, 'User Created'));
      closeModal();
    } else {
      addToast(createErrorToast(TOAST_TITLE, 'Error: User was not created.'));
    }
    setIsLoading(false);
  };

  return (
    <EuiModal onClose={closeModal} initialFocus='[name="username"]'>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Create User</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm id={formId} component='form' onSubmit={handleSubmit}>
          <EuiFormRow label='Username'>
            <EuiFieldText
              name='username'
              placeholder='username'
              onChange={setFormData}
            />
          </EuiFormRow>
          <EuiFormRow label='email'>
            <EuiFieldText
              name='email'
              placeholder='user@kgsresearch.com'
              onChange={setFormData}
            />
          </EuiFormRow>
          <EuiFormRow label='Password'>
            <EuiFieldPassword
              name='password'
              placeholder='password'
              onChange={setFormData}
            />
          </EuiFormRow>
          <EuiFormRow label='Role'>
            <EuiSelect
              name='role'
              value={formData.role}
              options={ROLE_OPTIONS}
              onChange={setFormData}
            />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>
        <EuiButton type='submit' form={formId} fill isLoading={isLoading}>
          Create User
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}

/** A modal with an embedded form to delete users */
function DeleteUserModal({
  closeModal, // modal state hook
}) {
  const [userToDelete, setUserToDelete] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const formId = htmlIdGenerator('del_usr')();
  const [users, setUsers] = useState([]);
  const { addToast } = useContext(ToastContext);
  const TOAST_TITLE = 'Delete User';

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userToDelete) {
      addToast(
        createErrorToast(TOAST_TITLE, 'Must provide a username to delete')
      );
      return;
    }
    setIsLoading(true);
    const response = await deleteUser(userToDelete);
    if (response) {
      closeModal();
    } else {
      addToast(createErrorToast(TOAST_TITLE, 'Error: Failed to delete user'));
      return;
    }

    addToast(createSuccessToast(TOAST_TITLE, 'Successfully deleted user'));
    setIsLoading(false);
  };

  useEffect(() => {
    const loadUsers = async () => {
      setUsers(await getAllUsers());
    };
    loadUsers();
  }, []);

  let options = users.map((user) => {
    return {
      value: user.username,
      text: `${user.username} - (${user.role})`,
    };
  });

  return (
    <EuiModal onClose={closeModal} initialFocus='[name="username"]'>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <h1>Delete User</h1>
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm id={formId} component='form' onSubmit={handleSubmit}>
          <EuiSelect
            name='user-select'
            onChange={(e) => setUserToDelete(e.target.value)}
            options={options}
          />
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={closeModal}>Cancel</EuiButtonEmpty>
        <EuiButton type='submit' form={formId} fill isLoading={isLoading}>
          Delete User
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}

export { CreateUserModal, DeleteUserModal };
