import './SampleTable.css';
import React from 'react';
import { EuiBasicTable, EuiText } from '@elastic/eui';
import { findUniqueIdColumn } from 'utils/sample';

/**
 * Component to display all a sample's contacts' data
 */
function SampleTable({ sample }) {
  const uniqueIdKey = findUniqueIdColumn(sample[0]);
  const columnNames = [uniqueIdKey, 'token', 'tn', 'firstname', 'email'];

  let columns = columnNames.map((header) => {
    return {
      field: header,
      name: header,
      render: (field) => {
        return <EuiText>{field}</EuiText>;
      },
    };
  });
  let items = [];

  if (uniqueIdKey) {
    items = sample.slice(0, 5);
  }

  return (
    <EuiBasicTable
      className='sampleTable'
      items={items}
      columns={columns}
      noItemsMessage='Require unique_id, tn, firstname, and token headers.'
      width='100%'
      tableCaption='This displays the first 5 rows and required columns'
    />
  );
}

export default SampleTable;
