import React from 'react';
import PropTypes from 'prop-types';
import { EuiImage } from '@elastic/eui';

/**
 * Test Text - MMS image preview
 */
function TestTextMmsPreview({ url }) {
  const mediaNotFoundUrl = '/media/mediaNotFound';

  if (!url) {
    url = mediaNotFoundUrl;
  }
  return (
    <EuiImage
      allowFullScreen={true}
      size='m'
      src={url}
      alt='MMS preview'
      onError={(e) => {
        e.target.src = mediaNotFoundUrl;
      }}
    />
  );
}

TestTextMmsPreview.propTypes = {
  /**
   * URL of an mms to load
   */
  url: PropTypes.string.isRequired,
};

export default TestTextMmsPreview;
