import axios from 'axios';

/**
 * Authenticate user credentials with server.
 * @param {Object} credentials An object containing the username and password
 *      {username, password}
 * @returns {Object} The servers response as an axiom object.
 */
async function authenticateUser(credentials) {
  return axios
    .post('/login', credentials)
    .then((res) => {
      if (res.status !== 401) {
        return res;
      } else {
        return false;
      }
    })
    .catch(() => {
      return false;
    });
}

export default authenticateUser;
