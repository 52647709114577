import React from 'react';
import PropTypes from 'prop-types';
import { EuiInMemoryTable, EuiIcon } from '@elastic/eui';
import { FORMATTED_SCRUB_REASON } from 'utils/constants';

/**
 * Create the table containing the campaign's contacts.
 *
 * @param {Array<Object>} list Contacts list
 */
function ContactsTable({ isLoading, contacts = [] }) {
  const columns = [
    {
      field: 'firstname',
      name: 'First Name',
      sortable: true,
    },
    {
      field: 'token',
      name: 'Token',
      sortable: true,
    },
    {
      field: 'tn',
      name: 'Phone Number',
      sortable: true,
    },
    {
      field: 'uniqueId',
      name: 'Unique ID',
      sortable: true,
    },
    {
      field: 'contacted',
      name: 'Sent',
      sortable: true,
    },
    {
      field: 'dnc',
      name: 'DNC',
      sortable: true,
      render: (dnc) => {
        const color = dnc ? 'danger' : 'success';
        const label = dnc ? 'crossInACircleFilled' : 'checkInCircleFilled';
        return <EuiIcon type={label} color={color} />;
      },
    },
    {
      field: 'scrubReason',
      name: 'Scrub Reason',
      sortable: true,
      render: (reason) => FORMATTED_SCRUB_REASON[reason],
    },
  ];

  const pagination = {
    initialPageSize: 100,
    pageSizeOptions: [100, 200, 500, 1000],
  };

  const search = {
    box: {
      incremental: true,
    },
  };

  contacts = contacts.map((contact) => {
    contact.tn = String(contact.tn);
    return contact;
  });

  return (
    <EuiInMemoryTable
      tableCaption='Project Contact List'
      items={contacts}
      loading={isLoading}
      message={'No contacts found.'}
      columns={columns}
      search={search}
      pagination={pagination}
      sorting={true}
    />
  );
}

ContactsTable.propTypes = {
  /**
   * Is loading status
   */
  isLoading: PropTypes.bool.isRequired,

  /**
   * Contacts array with contact objects
   */
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * First name
       */
      firstName: PropTypes.string.isRequired,

      /**
       * Last name
       */
      lastName: PropTypes.string.isRequired,

      /**
       * Contact telephone number
       */
      tn: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default ContactsTable;
