import { useContext, useState } from 'react';
import { EuiButtonIcon } from '@elastic/eui';
import PropTypes from 'prop-types';
import ToastContext from 'contexts/ToastContext';
import { createErrorToast } from 'utils/toasts';

/**
 * An icon to download a file
 */
function DownloadIcon({
  request,
  requestParams = [],
  filename = 'file',
  isDisabled = false,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useContext(ToastContext);
  const TOAST_TITLE = 'Download File';

  const getData = async () => {
    setIsLoading(true);
    let data = await request(...requestParams);

    if (data) {
      let element = document.createElement('a');
      element.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' + encodeURIComponent(data)
      );
      element.setAttribute('download', filename);
      element.style.display = 'none';
      element.click();
    } else {
      addToast(createErrorToast(TOAST_TITLE, 'Something went wrong.'));
    }
    setIsLoading(false);
  };

  return (
    <EuiButtonIcon
      onClick={getData}
      isLoading={isLoading}
      isDisabled={isDisabled}
      iconType='download'
    />
  );
}

DownloadIcon.propTypes = {
  /**
   * Request for file
   * @param {Object} options Optional request parameters
   */
  request: PropTypes.func.isRequired,

  /**
   * Request parameters to be passed into the request function
   */
  requestParams: PropTypes.array,

  /**
   * The file name for the file
   */
  filename: PropTypes.string,

  /**
   * Button disabled status
   */
  isDisabled: PropTypes.bool,
};

export default DownloadIcon;
