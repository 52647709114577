import React, { useContext } from 'react';
import { downloadWebsiteOptIns } from 'api/servicesApi';
import { createErrorToast } from 'utils/toasts';
import ToastContext from 'contexts/ToastContext';
import DownloadButton from 'components/widgets/DownloadButton';

/**
 * Component to download website opt in information
 */
function DownloadWebsiteOptIn() {
  const { addToast } = useContext(ToastContext);
  const TOAST_TITLE = 'Opt In Download';

  const handleDownload = async () => {
    try {
      const data = await downloadWebsiteOptIns();
      return data;
    } catch (error) {
      addToast(
        createErrorToast(
          TOAST_TITLE,
          'An error occurred while downloading the opt ins'
        )
      );
    }

    return null;
  };

  return (
    <DownloadButton
      filename={`website-optins-${process.env.REACT_APP_DEFAULT_TELECOM_PROVIDER}.csv`}
      request={handleDownload}
      text='Download Website Opt Ins'
    />
  );
}

export default DownloadWebsiteOptIn;
