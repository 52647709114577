import { EuiFormRow, EuiSelect } from '@elastic/eui';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { STATELIST } from '../../utils/constants';
/**
 * State select
 */
function StateSelect({ name, onChange, value, disabled }) {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(() => {
      let newOptions = STATELIST.map((e) => ({ value: e.name, text: e.name }));

      newOptions.unshift({
        value: '',
        text: '-- Please select a state --',
      });

      return newOptions;
    });
  }, []);

  useEffect(() => {
    const loadTnCounts = async () => {
      setIsLoading(false);
    };
    loadTnCounts();
  }, []);

  return (
    <EuiFormRow label='State'>
      <EuiSelect
        name={name}
        onChange={onChange}
        value={value}
        options={options}
        isLoading={isLoading}
        disabled={disabled}
      />
    </EuiFormRow>
  );
}

StateSelect.propTypes = {
  /**
   * The name of the select element
   */
  name: PropTypes.string,

  /**
   * The state update function for the containing form
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The value of the input
   */
  value: PropTypes.string.isRequired,

  /**
   * Disable the state selection
   */
  disabled: PropTypes.bool,
};

export default StateSelect;
