import React, { useEffect, useState } from 'react';
import ContactsTable from 'components/ContactsTable/ContactsTable';
import { getContacts, generateContactsCsv } from 'api/ContactsApi';
import { useParams, useNavigate } from 'react-router-dom';
import PageWithSidebar from 'templates/PageWithSidebar/PageWithSidebar';
import { EuiButton } from '@elastic/eui';
import DownloadButton from 'components/widgets/DownloadButton';

/**
 * Campaign's contacts component
 */
function Contacts({ setBannerText }) {
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const loadContacts = async () => {
    setIsLoading(true);
    setContacts(await getContacts(id));
    setIsLoading(false);
  };
  const buttons = [
    <EuiButton iconType='arrowLeft' onClick={() => navigate(`/campaign/${id}`)}>
      Back to Campaign Details
    </EuiButton>,
    <DownloadButton
      request={generateContactsCsv}
      requestParams={[contacts]}
      text='Download Contacts List'
      filename={`${id}_contacts.csv`}
    />,
  ];

  useEffect(() => {
    loadContacts();
  }, []);

  return (
    <PageWithSidebar
      sidebarButtons={buttons}
      bannerText='Active Contacts'
      setBannerText={setBannerText}
      pageBody={<ContactsTable isLoading={isLoading} contacts={contacts} />}
    />
  );
}

export default Contacts;
