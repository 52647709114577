import {
  EuiButton,
  EuiFlexGrid,
  EuiFlexItem,
  EuiPanel,
  EuiText,
} from '@elastic/eui';
import CampaignMessageList from './CampaignMessageList';
import SaveInviteModal from 'components/SaveInviteModal/SaveInviteModal';
import React, { useEffect, useRef, useState } from 'react';
import { getDefaultMessage } from 'api/CampaignMessagesApi';

/**
 * A campaigns message component
 */
function CampaignMessages({ title, isRegisteredMessage, invites, setInvites }) {
  const [modalIsVisible, setModalIsVisible] = useState();
  const defaultInviteRef = useRef({});
  let modal;

  useEffect(() => {
    const loadDefaultMessage = async () => {
      // Get default unregistered invite
      const { message: defaultMessage, mediaUrl: defaultMms } =
        await getDefaultMessage(isRegisteredMessage);

      defaultInviteRef.value = {
        message: defaultMessage,
        mediaUrl: defaultMms,
      };
    };
    if (!isRegisteredMessage) {
      loadDefaultMessage();
    }
  }, []);

  if (modalIsVisible) {
    modal = (
      <SaveInviteModal
        invite={defaultInviteRef.value}
        isRegisteredMessage={isRegisteredMessage}
        onClose={() => setModalIsVisible(false)}
      />
    );
  }

  return (
    <EuiPanel hasBorder={true} hasShadow={false}>
      <EuiFlexGrid>
        <EuiFlexItem grow={9}>
          <EuiText size='m' textAlign='left'>
            <h2>{title}</h2>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton
            onClick={() => {
              setModalIsVisible(true);
            }}
          >
            Create New Invite
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGrid>
      <EuiFlexGrid>
        <EuiFlexItem grow={9}>
          <CampaignMessageList
            invites={invites}
            isRegisteredMessage={isRegisteredMessage}
            setInvites={setInvites}
          />
        </EuiFlexItem>
        {modal}
      </EuiFlexGrid>
    </EuiPanel>
  );
}

export default CampaignMessages;
