import React, { useContext, useState } from 'react';
import { addToGlobalDncList } from 'api/DNCApi';
import {
  EuiFilePicker,
  EuiButton,
  EuiEmptyPrompt,
  EuiText,
  EuiCodeBlock,
  EuiListGroup,
  EuiListGroupItem,
} from '@elastic/eui';
import { createErrorToast, createSuccessToast } from 'utils/toasts';
import ToastContext from 'contexts/ToastContext';

/**
 * Upload a list of phone numbers to the
 * DNC service.
 */
function UploadDnc() {
  const [uploadedFile, setUploadedFile] = useState();
  const { addToast } = useContext(ToastContext);
  const TOAST_TITLE = 'Upload DNC';

  const uploadDnc = async () => {
    let result = await addToGlobalDncList(uploadedFile);

    if (!result) {
      addToast(createErrorToast(TOAST_TITLE, 'Something went wrong.'));
    } else {
      addToast(createSuccessToast(TOAST_TITLE, 'Successfully added DNCs.'));
    }
  };

  return (
    <>
      <EuiEmptyPrompt
        template='centeredContent'
        title={<h2>Upload a list of DNCs</h2>}
        body={
          <EuiFilePicker
            name='dnc tool'
            onChange={(e) => {
              setUploadedFile(e[0] ?? []);
            }}
            accept={['txt', 'csv']}
          />
        }
        footer={
          <EuiButton onClick={() => uploadDnc()}>Upload DNC List</EuiButton>
        }
      />
      <EuiText>
        <h3>File Formatting</h3>
      </EuiText>
      <EuiListGroup maxWidth={false}>
        <EuiListGroupItem label='1. File type is a .csv or .txt file.  (e.g. dncs_to_upload.csv)' />
        <EuiListGroupItem label='2. "DNC" as a header of the column' />
        <EuiListGroupItem label='3. A single phone number on each subsequent line' />
      </EuiListGroup>
      <EuiCodeBlock paddingSize='s' fontSize='m'>
        {`DNC
        1234567890
        4351268765
        6452456778`}
      </EuiCodeBlock>
    </>
  );
}

export default UploadDnc;
