import { EuiIcon, EuiText } from '@elastic/eui';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Test Text status component
 */
function TestTextStatus({ status }) {
  let statusIcon = <EuiIcon type='crossInACircleFilled' size='m' color='red' />;

  if (status > 0) {
    statusIcon = <EuiIcon type='checkInCircleFilled' size='m' color='green' />;
  }

  return <EuiText>Test Text Sent {statusIcon}</EuiText>;
}

TestTextStatus.propTypes = {
  // Has the invite been tested yet
  status: PropTypes.bool.isRequired,
};

export default TestTextStatus;
