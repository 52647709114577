import React from 'react';
import {
  EuiButton,
  EuiTitle,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
} from '@elastic/eui';
import PropTypes from 'prop-types';

/**
 * The site header, containing global redirects
 */
function Banner({ setTokens, bannerText }) {
  const logout = () => {
    setTokens({});
  };

  return (
    <div className='banner'>
      <EuiFlexGroup>
        <EuiFlexItem grow={1}>
          {process.env.REACT_APP_LOGO_IMAGE ? (
            <EuiImage
              size='s'
              margin='s'
              src={
                process.env.PUBLIC_URL + '/' + process.env.REACT_APP_LOGO_IMAGE
              }
            />
          ) : (
            <EuiText className='logo'>
              <p>{process.env.REACT_APP_PLATFORM_NAME}</p>
            </EuiText>
          )}
        </EuiFlexItem>
        <EuiFlexItem grow={8}>
          <EuiTitle>
            <h1>{bannerText}</h1>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={1}>
          <EuiButton className='logout' onClick={logout} fill>
            Logout
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}

Banner.propTypes = {
  /**
   * Token state update function (for logging out)
   */
  setTokens: PropTypes.func,

  /**
   * Header text
   */
  bannerText: PropTypes.any,
};

export default Banner;
