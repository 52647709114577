import React, { useState } from 'react';
import { filterDndsFromList } from 'api/DNCApi';
import DownloadButton from 'components/widgets/DownloadButton';
import {
  EuiCodeBlock,
  EuiEmptyPrompt,
  EuiFilePicker,
  EuiListGroup,
  EuiListGroupItem,
  EuiText,
} from '@elastic/eui';

/**
 * Component to filter a file of phone numbers
 * using the DNC Service.
 */
function FilterDnc() {
  const [uploadedFile, setUploadedFile] = useState();

  return (
    <>
      <EuiEmptyPrompt
        template='centeredContent'
        title={<h2>Filter DNCs from list</h2>}
        body={
          <EuiFilePicker
            name='dnc tool'
            onChange={(e) => {
              setUploadedFile(e[0] ?? []);
            }}
            accept={['txt', 'csv']}
          />
        }
        footer={
          <DownloadButton
            request={filterDndsFromList}
            requestParams={[uploadedFile]}
            text='Filter List of Phone Numbers'
            filename='filtered-numbers'
          />
        }
      />
      <EuiText>
        <h3>File Formatting</h3>
      </EuiText>
      <EuiListGroup maxWidth={false}>
        <EuiListGroupItem label='1. File type is a .csv or .txt file. (e.g. tns_to_filter.csv)' />
        <EuiListGroupItem label='2. A single phone number on each line' />
      </EuiListGroup>
      <EuiCodeBlock paddingSize='s' fontSize='m'>
        {'1234567890\n4351268765\n6452456778'}
      </EuiCodeBlock>
    </>
  );
}

export default FilterDnc;
