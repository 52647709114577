import { EuiIcon, EuiText } from '@elastic/eui';
import CampaignContext from 'contexts/CampaignContext';
import React, { useContext } from 'react';

/**
 * Component to display the URL status of a campaign
 */
function UrlStatus() {
  const { validLongUrl } = useContext(CampaignContext);
  let urlStatusIcon = (
    <EuiIcon type='crossInACircleFilled' size='m' color='red' />
  );

  if (validLongUrl) {
    urlStatusIcon = (
      <EuiIcon type='checkInCircleFilled' size='m' color='green' />
    );
  }

  return <EuiText>URL Validation {urlStatusIcon}</EuiText>;
}
export default UrlStatus;
