import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EuiButton, EuiTab, EuiTabs } from '@elastic/eui';
import UserContext from 'contexts/UserContext';
import { downloadConversations } from 'api/ConversationApi';
import PageWithSidebar from 'templates/PageWithSidebar/PageWithSidebar';
import CampaignList from 'components/CampaignList/CampaignList';
import CampaignDetailsModal from 'components/CampaignDetailsModal/CampaignDetailsModal';
import PropTypes from 'prop-types';
import DownloadFileModal from 'components/DownloadFilesModal/DownloadFileModal';
import { getGlobalDncList } from 'api/DNCApi';
import DownloadButton from 'components/widgets/DownloadButton';
import DownloadWebsiteOptIn from 'components/DownloadWebsiteOptIn/DownloadWebsiteOptIn';

/** The landing screen for when a user is logged in. */
function CampaignDashboard({ setBannerText }) {
  const navigate = useNavigate();
  const { role } = useContext(UserContext);
  const [curModal, setModal] = useState();
  const [campaignType, setCampaignType] = useState('active');
  const closeModal = () => setModal();
  let adminDashboardButton;

  if (role === 'admin') {
    adminDashboardButton = (
      <EuiButton onClick={() => navigate('/admin')}>Admin Dashboard</EuiButton>
    );
  }

  const showCampaignModal = () => {
    setModal(<CampaignDetailsModal closeModal={closeModal} />);
  };

  let showDownloadModal = () => {
    setModal(
      <DownloadFileModal
        title='Download Conversations'
        onSubmit={downloadConversations}
        closeModal={closeModal}
        filename='conversations.csv'
      />
    );
  };

  let pageBody = (
    <div className='campaign-dashboard'>
      <EuiTabs>
        <EuiTab
          onClick={() => {
            setCampaignType('active');
          }}
          isSelected={campaignType === 'active'}
        >
          Active
        </EuiTab>
        <EuiTab
          onClick={() => {
            setCampaignType('completed');
          }}
          isSelected={campaignType === 'completed'}
        >
          Completed
        </EuiTab>
      </EuiTabs>
      <CampaignList campaignType={campaignType} />
      {curModal}
    </div>
  );

  let sidebarButtons = [
    <EuiButton
      onClick={showCampaignModal}
    >
      Create Campaign
    </EuiButton>,
    <EuiButton onClick={() => navigate('/tools')}>Tools</EuiButton>,
    <EuiButton onClick={showDownloadModal}>Download Conversations</EuiButton>,
    <DownloadButton
      request={getGlobalDncList}
      text='Download Global DNC List'
      filename='master-dnc'
    />,
    <DownloadWebsiteOptIn>Download Website Opt Ins</DownloadWebsiteOptIn>,
  ];

  if (adminDashboardButton) {
    sidebarButtons.unshift(adminDashboardButton);
  }

  return (
    <PageWithSidebar
      bannerText='Campaign Dashboard'
      setBannerText={setBannerText}
      pageBody={pageBody}
      sidebarButtons={sidebarButtons}
      homeButton={false}
    />
  );
}

CampaignDashboard.propTypes = {
  /**
   * banner text hook
   * @param {string} bannerText The string to set the banner text to
   */
  setBannerText: PropTypes.func.isRequired,
};

export default CampaignDashboard;
