import { STATELIST } from 'utils/constants';

/**
 * Convert a full length zone into a zone code.
 * e.g. Alabama -> AL
 *
 * @param {String} zone Fullname project zone
 * @returns {String} Two letter zone code
 */
function zoneToCode(zone) {
  for (let state of STATELIST) {
    if (state.name === zone) {
      return state.code;
    }
  }
}

/**
 * Get the fullname of a zone
 * @param {String} zone Abbreviated zone
 * @returns {String} Fullname of zone
 */
function getZoneFullname(zone) {
  for (let state of STATELIST) {
    if (state.code === zone) {
      return state.name;
    }
  }
}
export { zoneToCode, getZoneFullname };
