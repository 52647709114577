import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFilePicker,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiSelect,
  EuiTextArea,
  htmlIdGenerator,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui';
import { updateCampaignMessage } from 'api/CampaignMessagesApi';
import TestTextMmsPreview from 'components/TestTextMmsPreview/TestTextMmsPreview';
import ToastContext from 'contexts/ToastContext';
import useFormData from 'hooks/useFormData';
import { useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MMS_EXTENSIONS } from 'utils/constants';
import { validateMessage } from 'utils/invites';
import { findSubstring } from 'utils/strings';
import { createErrorToast, createSuccessToast } from 'utils/toasts';

/**
 * Create/Update an invite message modal
 */
function SaveInviteModal({
  invite = {},
  isRegisteredMessage = false,
  onClose,
}) {
  const formId = htmlIdGenerator('save-invite')();
  const [formData, setFormData] = useFormData({
    name: invite.name || '',
    type: invite.type || '',
    message: invite.message || '',
    mms: invite.mms || '',
    mediaUrl: invite.mediaUrl || '',
  });
  const [isLoading, setIsLoading] = useState();
  const messageTypes = [{ text: 'Initial' }, { text: 'Reminder' }];
  const { id } = useParams();
  const filePickerRef = useRef();
  const { addToast } = useContext(ToastContext);
  const TOAST_TITLE = 'Invite';

  const handleMmsUpload = (file) => {
    const textingAbbrev = {
      bandwidth: 'BW',
      telnyx: 'TL',
    };

    const validName =
      textingAbbrev[process.env.REACT_APP_DEFAULT_TELECOM_PROVIDER];
    if (!validName) {
      console.error(validName);
      addToast(
        createErrorToast('Can not validate image name. Contact admins.')
      );
      return;
    }

    if (file?.name && !findSubstring(file.name, validName, false)) {
      addToast(
        createErrorToast(
          `Attached image filename (${file.name}) must contain ${validName}`
        )
      );
      setFormData({ target: { name: 'mms', value: null } });
      filePickerRef.current.removeFiles();
      return;
    }
    setFormData({
      target: {
        name: 'mms',
        value: file,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name) {
      addToast(createErrorToast(TOAST_TITLE, 'Must provide an invite name'));
      return;
    }

    if (!formData.type) {
      addToast(createErrorToast(TOAST_TITLE, 'Must select an invite type'));
      return;
    }

    if (!formData.message) {
      addToast(createErrorToast(TOAST_TITLE, 'Must provide an invite message'));
      return;
    }

    const errorMessage = validateMessage(formData.message, isRegisteredMessage);
    if (errorMessage) {
      addToast(createErrorToast(TOAST_TITLE, errorMessage));
      return;
    }

    setIsLoading(true);
    let res = await updateCampaignMessage(
      id,
      formData.name,
      formData.type,
      formData.message,
      formData.mms,
      invite._id,
      isRegisteredMessage,
      formData.mediaUrl
    );
    setIsLoading(false);

    if (!res) {
      addToast(createErrorToast(TOAST_TITLE, 'Failed to save invite'));
    } else {
      addToast(createSuccessToast(TOAST_TITLE, 'Invite saved'));
      onClose();
      window.location.reload();
    }
  };

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>Save an Invite</EuiModalHeader>
      <EuiModalBody>
        <EuiForm component='form' id={formId} onSubmit={handleSubmit}>
          <EuiFormRow label='Name'>
            <EuiFieldText
              name='name'
              value={formData.name}
              onChange={setFormData}
            />
          </EuiFormRow>
          <EuiFormRow label='Type'>
            <EuiSelect
              name='type'
              value={formData.type}
              options={messageTypes}
              onChange={setFormData}
              hasNoInitialSelection={true}
            />
          </EuiFormRow>
          <EuiFormRow label={`Message (${formData.message.length} characters)`}>
            <EuiTextArea
              name='message'
              value={formData.message}
              onChange={setFormData}
            />
          </EuiFormRow>
          <EuiFormRow label='MMS'>
            <EuiFilePicker
              name='mms'
              ref={filePickerRef}
              onChange={(e) => {
                handleMmsUpload(e[0] ?? null);
              }}
              accept={MMS_EXTENSIONS}
            />
          </EuiFormRow>
          <EuiFormRow>
            <EuiFlexGroup direction='column'>
              <EuiFlexItem>
                {formData.mms ? (
                  <TestTextMmsPreview
                    url={URL?.createObjectURL(formData.mms ?? {})}
                  />
                ) : (
                  <TestTextMmsPreview url={'/media/' + formData.mediaUrl} />
                )}
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiButton
                  onClick={() =>
                    setFormData({
                      target: {
                        name: 'mediaUrl',
                        value: '',
                      },
                    })
                  }
                  color='warning'
                  fill
                >
                  Remove Saved MMS
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
        <EuiButton type='submit' form={formId} isLoading={isLoading}>
          Save
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
}

export default SaveInviteModal;
