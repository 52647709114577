import AppApi from 'services/appApi';

/**
 * Return a shortened URL from a long URL
 * @param {String} longUrl Full URL to be shortened
 * @returns {String} shortened URL, or false if failed
 */
async function generateShortUrl(longUrl) {
  try {
    let res = await AppApi.post('/generateShortUrl', {
      destination: longUrl,
    });

    if (res.status !== 200) {
      throw res;
    }

    return res.data.shortUrl;
  } catch (error) {
    console.error(error);
    return '';
  }
}

export { generateShortUrl };
