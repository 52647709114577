import AppApi from '../services/appApi';

/**
 * Download a list of subscribers to a phone number
 *
 * @returns {Buffer} Subscriber file data
 */
async function downloadSubscribers() {
  try {
    let res = await AppApi.get('/downloadSubscribers');

    if (res.status !== 200) {
      throw res;
    }
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * Download a list of users who opt-in via text message
 *
 * @returns {Buffer} Opt-In file data
 */
async function downloadOptIns() {
  try {
    let res = await AppApi.get('/downloadOptIns');
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * Download users who opted in via the website
 * @returns
 */
async function downloadWebsiteOptIns() {
  try {
    let res = await AppApi.get('/downloadWebsiteOptIns');
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * Get carrier data by date.
 *
 * @param {Date} startDate Initial date to get carrier data from
 * @param {Date} endDate Last date to get carrier data from
 * @returns Array<Object> List of carrier data by date
 */
async function getCarrierData(startDate, endDate) {
  try {
    let carrierData = await AppApi.get('/getCarrierData', {
      params: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
    });
    return carrierData.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

/**
 * Lookup information about a list of telephone numbers.
 *
 * @param {File} tnFile - CSV File containing the TNs to lookup. TNs are under the 'phonef' column.
 * @returns {Boolean} - true on TNs submitted successfully.
 */
async function tnLookup(tnFile) {
  try {
    let formData = new FormData();
    formData.append('contacts', tnFile);

    await AppApi.post('/tnLookup', formData);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}
export {
  downloadSubscribers,
  downloadOptIns,
  downloadWebsiteOptIns,
  getCarrierData,
  tnLookup,
};
