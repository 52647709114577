import AppApi from 'services/appApi';

/**
 * Send a test text message
 * @param {String} campaignId ID of campaign
 * @param {String} phoneNumber test phone number
 * @param {String} messageId ID of message to be sent
 * @param {String} sampleId ID of a sample to test
 * @param {String} telecom telecom to send text with
 * @returns {Boolean} success or fail
 */
async function sendTestText(
  campaignId,
  phoneNumber,
  messageId,
  sampleId,
  telecom
) {
  try {
    let res = await AppApi.post('/sendTestText', {
      projectId: campaignId,
      phoneNumber: phoneNumber,
      messageId: messageId,
      sampleId: sampleId,
      telecom: telecom,
    });

    if (res.status !== 200) {
      throw res;
    }

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

/**
 *
 * @param {String} campaignId ID of a campaign
 * @param {String} inviteId ID of an invite message
 * @param {String} sampleId ID of a sample
 * @param {String} sampleType Type of sample (registered/unregistered)
 * @returns {Object} Text preview data (message, mmsUrl)
 */
async function getTestTextPreview(campaignId, inviteId, sampleId, sampleType) {
  try {
    let result = await AppApi.get('/getTestTextPreview', {
      params: {
        projectId: campaignId,
        messageId: inviteId,
        sampleId: sampleId,
        sampleType: sampleType,
      },
    });

    if (result.status !== 200) {
      throw result;
    }

    return result.data;
  } catch (error) {
    return { message: '', shortUrl: '', mmsUrl: '' };
  }
}
export { sendTestText, getTestTextPreview };
