import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  htmlIdGenerator,
} from '@elastic/eui';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Sidebar component
 */
function Sidebar({ buttons }) {
  return (
    <EuiPanel paddingSize='m'>
      <EuiFlexGroup direction='column'>
        {buttons.map((btn) => {
          return <EuiFlexItem key={htmlIdGenerator()()}>{btn}</EuiFlexItem>;
        })}
      </EuiFlexGroup>
    </EuiPanel>
  );
}

Sidebar.propTypes = {
  /**
   * Array of react button components to display
   */
  buttons: PropTypes.arrayOf(PropTypes.object),
};

export default Sidebar;
